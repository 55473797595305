import * as types from './types';

// Change Lang
export function navOnScroll(show){
    return {
        type: types.NAV_ON_SCROLL,
        show
    }
}

export function toggleMobileMenu(){
    return{
        type: types.NAV_TOGGLE_MOBILE
    }
}