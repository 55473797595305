import * as types from "../actions/types";

const initialState = {
	activeLang: "en",
	nav: {
		research: {
			en: "research",
			no: "undersøkelser"
		},
		contributors: {
			en: "contributors",
			no: "bidragsytere"
		},
		news: {
			en: "news",
			no: "nyhet"
		},
		forDevelopers: {
			en: "for developers",
			no: "for utviklere"
		}
	},
	landingPage: {
		heading: {
			en: "sustainable retail",
			no: "bærekraftig detaljhandel"
		},
		quote1: {
			en: "<b>Digitize receipts</b> for",
			no: "<b>Digitaliser kvitteringer</b> for"
		},
		// quote2: {
		// 	en: "and increased <b>consumer benefits</b>.",
		// 	no: "og <b>økte</b> forbrukerfordeler"
		// },
		vote: {
			en: "the research",
			no: "forskningen"
		}
	},
	weWaste: {
		heading: {
			en: "Since you entered this website..."
			// no: 'vi kaster bort'
		},
		trees: {
			en: "trees...",
			no: "trær"
		},
		water: {
			en: "liters of water...",
			no: "liter vann"
		},
		co2: {
			en: "kilos of CO₂...",
			no: "kilo CO2"
		},
		quote: {
			en: "... were wasted in favor of <b>paper receipts</b>"
			// no: '<b>hver dag</b> til fordel for <b>papirkvitteringer</b>'
		}
	},
	goal: {
		p1: {
			en: "Our goal",
			no: "målet for"
		},
		p2: {
			en:
				"is to automatically deliver the receipts to you digitally where you want them, when your purchase is made.",

			/* "is to <b>connect the point of purchase</b> to <b>your preferred app</b>, in order to <b>receive your receipts wherever you want them</b>.", */
			no:
				"er å <b>koble kjøpspunktet</b> til din <b>foretrukne app</b> for å få kvitteringen <b>der du vil</b>."
		},
		p3: {
			en: "That way, we eliminate the purpose of the paper receipt.",
			no: "ved å gjøre det, fjerner vi formålet med papirkvitteringen"
		}
		// p4: {
		//     en: 'TheZeiptProject creates sustainable retail and makes digital receipts happen.',
		//     no: 'TheZeiptProject skaper bærekraftig detaljhandel og får digitale kvitteringer til å skje.'
		// }
	},
	vote: {
		heading: {
			en: "who should offer <span>digital receipts</span>?",
			no: "hvem som skal tilby <span>digitale kvitteringer</span>?"
		},
		btn1: {
			en: "electronic stores",
			no: "elektroniske butikker"
		},
		btn2: {
			en: "clothing stores",
			no: "klesbutikker"
		},
		btn3: {
			en: "restraurants",
			no: "restauranter"
		},
		btn4: {
			en: "travel",
			no: "reise"
		},
		other: {
			en: "other",
			no: "annen"
		},
		placeholder: {
			en: "please specify",
			no: "vennligst spesifiser"
		},
		submit: {
			en: "submit",
			no: "sende inn"
		}
	},
	voting: {
		heading: {
			en: "thank you for <span>your vote</span>!",
			no: "takk for <span>din stemme</span>!"
		},
		desc1: {
			en: "your vote was between ",
			no: "din stemme var mellom"
		},
		desc2: {
			en: "of other choices",
			no: "av andre valg"
		},
		involve: {
			en: "involve the others",
			no: "involver de andre"
		}
	},
	pieChart: {
		en: [
			"Electronic Stores",
			"Clothing Stores",
			"Restaurants",
			"Travel",
			"Other"
		],
		no: [
			"Elektroniske butikker",
			"Klesbutikker",
			"Restauranter",
			"Reise",
			"Andre"
		]
	},
	footer: {
		contacts: {
			en: "Contacts",
			no: "kontakter"
		},
		follow: {
			en: "Follow our journey",
			no: "følg reisen vår"
		},
		address: {
			en: "Address",
			no: "adresse"
		}
	},
	info: {
		intro: {
			en:
				"So how do receipts impact our planet, am I at risk of increasing my chances of diabetes? What are the advantages and disadvantages of today's receipts, and how can we solve the challenges they currently cause?",
			no:
				"Så hvordan påvirker kvitteringer planeten vår, risikerer jeg å øke sjansene for diabetes? Hva er fordeler og ulemper med dagens kvitteringer, og hvordan kan vi løse utfordringene de for øyeblikket medfører?"
		},
		slides: [
			{
				en: "Paper receipts are the single biggest contributor to BPA",
				no: "Papirkvitteringer er den største bidragsyteren til BPA"
			},
			{
				en:
					"of norwegians said that when they needed the receipt it’s either lost, faded or binned",
				no:
					"av nordmenn sa at når de trengte kvitteringen, er den enten tapt, falmet eller renset"
			},
			{
				en:
					"... all this for creating a product with an average life span of less than 30 seconds",
				no:
					"... alt dette for å lage et produkt med en gjennomsnittlig levetid på under 30 sekunder"
			}
		],
		btn: {
			en: "The Research",
			no: "forskningen"
		}
	},
	dates: {
		day: {
			en: [
				"Sunday",
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday"
			],
			no: [
				"Søndag",
				"Mandag",
				"Tirsdag",
				"Onsdag",
				"Torsdag",
				"Fredag",
				"Lørdag"
			]
		},
		month: {
			en: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			],
			no: [
				"Januar",
				"Februar",
				"Mars",
				"April",
				"Mai",
				"Juni",
				"Juli",
				"August",
				"September",
				"Oktober",
				"November",
				"Desember"
			]
		}
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.CHANGE_LANG: {
			return { ...state, activeLang: action.lang };
		}

		default:
			return state;
	}
};
