import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actions from "./actions/posts";
import Nav from "./components/Nav/Nav";
import LandingPage from "./components/LandingPage/LandingPage";
import News from "./components/News/News";
import Research from "./components/Research/Research";
import Footer from "./components/Footer/Footer";
import Contributors from "./components/Contributors/Contributors";
import Error from "./components/common/Error/Error";

class App extends Component {
	componentDidMount() {
		this.props.fetchPosts();
	}

	render() {
		return (
			<HashRouter basename="/">
				<div className={style.App}>
					<Nav />
						<Switch>
							<Route path="/" exact component={LandingPage} />
							<Route path="/news" component={News} />
							<Route path="/research" component={Research} />
							<Route path="/contributors"  component={Contributors} />
							<Route component={Error}/>
						</Switch>
					<Footer />
				</div>
			</HashRouter>
		);
	}
}

const mapStateToProps = state => {
	return {
		posts: state.posts,
		count: state.count
	};
};

export default connect(mapStateToProps, actions)(App);
