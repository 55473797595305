import React, { Component } from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actions from "../../../actions/research";

class Sources extends Component {
  render() {
    return (
      <div id="sources" className={style.trees}>
        <h3>References for The Research</h3>
        <p>
          <strong>The People</strong>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1eb4HTCVBUpyv94GfJ4obIanvjqh8Ra6ZxKZ6MOC9KLY/edit?usp=sharing"
            >
              Zeipts receipt consumer research
            </a>
            , Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.capgemini.com/news/automation-in-retail-stores/?fbclid=IwAR3B1f6D7LZLN_inQa2l4_DE1xkmaTDiIu30HXF4x5g45WEq-lQK_KxQ23g"
            >
              Report: Automation in Retail Stores
            </a>
            , Capgemini, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.capgemini.com/wp-content/uploads/2020/01/Automation-in-Retail_Infographic.pdf"
            >
              Smart Stores
            </a>
            , Capgemini, Retrieved Jan 2020
          </li>
          <strong>Toxicity</strong>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5453537/"
            >
              Handling of thermal paper: Implications for dermal exposure to
              bisphenol A and its alternatives
            </a>
            , National Center for Biotechnology Information, Retrieved Feb 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.medicalnewstoday.com/articles/221205.php"
            >
              How does bisphenol A affect health?
            </a>
            , MedicalNewsToday, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nature.com/news/2010/101104/full/news.2010.581.html?source=post_page-----9f1763bd0526----------------------"
            >
              Bisphenol A goes through the skin
            </a>
            , nature.com, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://cen.acs.org/articles/95/i35/Touching-thermal-paper-receipts-extend.html?source=post_page-----9f1763bd0526----------------------"
            >
              Touching thermal-paper receipts could extend BPA retention in the
              body
            </a>
            , c&en, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ewg.org/research/bpa-in-store-receipts?source=post_page-----9f1763bd0526----------------------"
            >
              BPA Coats Cash Register Receipts
            </a>
            , ewg, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ewg.org/research/new-study-confirms-bpa-receipts?source=post_page-----9f1763bd0526----------------------"
            >
              New Study Confirms BPA in Receipts
            </a>
            , ewg, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.bisphenol-a-europe.org/wp-content/uploads/2017/04/How-much-BPA-does-a-typical-person-take-in-through-a-normal-diet.pdf"
            >
              How much BPA does a typical person take in through a normal diet?
            </a>
            , Bisphenol A Europe, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://chemicalwatch.com/77769/switzerland-bans-the-use-of-bpa-and-bps-in-thermal-paper"
            >
              Switzerland bans the use of BPA and BPS in thermal paper
            </a>
            , Chemical Watch, Retrieved Jan 2020
          </li>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              //href="https://environmentaldefence.ca/report/bpa-and-bps-in-receipts/"
              href="https://environmentaldefence.ca/wp-content/uploads/2019/02/The-hidden-cost-of-receipts-BPA-BPS-Feb-2019-1.pdf"
            >
              The hidden cost of receipts
            </a>
            , Environmental Defence, Retrieved Jan 2020
          </li>

          <strong>Nature</strong>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.prnewswire.com/news-releases/thermal-paper-sales-witness-subdued-growth-as-shift-to-digital-payment-reduces-demand-finds-fact-mr-853012031.html"
            >
              Thermal Paper Sales Witness Subdued Growth as Shift to Digital
              Payment Reduces Demand, Finds Fact.MR
            </a>
            , PR Newswire, Retrieved Jan 2020
          </li>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://environmentalpaper.org/wp-content/uploads/2018/04/StateOfTheGlobalPaperIndustry2018_FullReport-Final.pdf"
            >
              The State of the Global Paper Industry
            </a>
            , Environmental Paper Network, Retrieved Jan 2020
          </li>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.prnewswire.com/news-releases/global-bisphenol-a-market-overview-2016-2022---market-is-projected-to-reach-us225-billion-by-2022-up-from-156-billion-in-2016---research-and-markets-300303934.html"
            >
              Global Bisphenol-A Market Overview 2016-2022 - Market is Projected
              to Reach US$22.5 Billion by 2022, Up from $15.6 Billion in 2016 -
              Research and Markets
            </a>
            , PR Newswire, Retrieved Jan 2020
          </li>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://echa.europa.eu/documents/10162/c6a8003c-81f3-4df6-b7e8-15a3a36baf76"
            >
              BPA_Dossier_Restriction_annexe XV_resubmission_Conformity
            </a>
            , European Chemicals Agency, Retrieved Jan 2020
          </li>

          <strong>Trees</strong>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pachamama.org/effects-of-deforestation"
            >
              Effects of Deforestation
            </a>
            , Pachamama Alliance, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.epa.gov/ghgemissions/sources-greenhouse-gas-emissions"
            >
              Sources of Greenhouse Gas Emissions
            </a>
            , United States Environmental Protection Agency, Retrieved Jan 2020
          </li>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nationmaster.com/country-info/stats/Geography/Area/Total"
            >
              Geography, area, countries compared
            </a>
            , nationmaster, Retrieved Jan 2020
          </li>

          <strong>Water</strong>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.maconwater.org/system/media_files/attachments/652/original/Sample%20Rate%20Calculations%202015.pdf?1421284581"
            >
              Sample Rate Calculations 2015
            </a>
            , THE MACON WATER AUTHORITY, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.huffpost.com/entry/why-wasting-water-is-even_b_11114690?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAMJcrqqA2ygqfOgIrGquazCac96YHq4QxNvwcLg5VeBGxKrgDRXVljndIuJMEQt41ujyIx5xmz6GiSBZo854UF_Rt4PHl4l0vpwA48ojeQ_-BOwggZZ1G1W_JaknGCn7VmXpnzHfFzRGvyrhkXYNlVXjoK9zXBk-FWX1k6yKBb9B&guccounter=2"
            >
              Why Wasting Water Is Even Worse for the Environment Than You Think
            </a>
            , huffpost, Retrieved Jan 2020
          </li>
          <strong>CO2</strong>

          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://climate.nasa.gov/effects/"
            >
              The Effects of Climate Change
            </a>
            , NASA, Retrieved Jan 2020
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sciencing.com/list-5921485-effects-carbon-dioxide-air-pollution.html"
            >
              The Effects of Carbon Dioxide on Air Pollution
            </a>
            , Sciencing, Retrieved Jan 2020
          </li>

          <strong>Calculator</strong>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://c.environmentalpaper.org/"
            >
              Paper Calculator 4.0
            </a>
            , Environmental Paper Network, Retrieved Jan 2020
          </li>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    research: state.research,
  };
};

export default connect(mapStateToProps, actions)(Sources);
