import * as types from '../actions/types';

const initialState = {
    allPosts: null,
    postsPerPage: null,
    singlePost: null,
    filteredPosts: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_POSTS: {
            // SET POST PER PAGE
            let postsPerPage;
            if (window.innerWidth > 900) {
                postsPerPage = 5;
            } else if (window.innerWidth > 600 && window.innerWidth <= 900) {
                postsPerPage = 3;
            } else {
                postsPerPage = 2;
            }

            // Mapping through all the fetched posts and adding "active" property // SORTING
            const allPosts = action.posts
                .filter(post => post.tags && !post.tags.includes('investment'))
                .map(post => {
                    return {...post, active: false}
                })

            // Check if there is an event today
            const now = new Date();
            const nowDate = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}`;

            const dates = allPosts.map(post => {
                const postYear = new Date(post.publishedAt).getFullYear();
                const postMonth = new Date(post.publishedAt).getMonth() + 1;
                const postDay = new Date(post.publishedAt).getDate();
                const postFullDate = `${postYear}${postMonth}${postDay}`;
                return postFullDate - nowDate === 0;
            });

            if(!dates.includes(true)){
                allPosts.push({
                    _id: 99999999999999999999,
                    publishedAt: now,
                    title: 'today'
                });
            }

            allPosts.sort((a, b) => {
                const dateA = new Date(a.publishedAt), dateB = new Date(b.publishedAt);
                return dateA - dateB;
            });

            return {
                ...state,
                allPosts,
                filteredPosts: allPosts,
                postsPerPage,
                loading: false
            }
        }

        case types.POSTS_TOGGLE_ACTIVE: {
            const allPosts = [...state.allPosts];
            const idArray = allPosts.map(post => {
                return post._id
            })
            const singlePostIndex = `${idArray.indexOf(action.id)}`;

            // return {...state, singlePost: singlePostIndex, closestPost: false}
            return {...state, singlePost: singlePostIndex}
        }

        case types.POSTS_CLOSE_ACTIVE:
            return {...state, singlePost: null};

        case types.GO_TO_CURRENT_POSTS: {
            const updState = {...state}
            const currentPosts = updState.currentPosts = action.index
            return {...state, currentPosts}
        }

        case types.TOGGLE_ACTIVE_FILTERS: {
            const filteredPosts = [...state.allPosts].filter(post => {
                // Check if there is tags property
                if (post.tags && post.tags.includes(action.filter)) {
                    // If clicked filter is in the post tags than return that post
                    return post
                } else {
                    return null
                }
            });

            return {...state, filteredPosts}
        }

        case types.CLEAR_ACTIVE_FILTERS: {
            const filteredPosts = [...state.allPosts];
            return {
                ...state,
                filteredPosts
            }
        };

        case types.FETCHING_POSTS: {
            return {...state, loading: true}
        }

        default:
            return state;
    }
};