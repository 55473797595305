import React, { Component, Fragment } from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actionsNav from "../../actions/nav";
import * as actionsContributors from "../../actions/contributors";
import Contributor from "./Contributor";
import ContributorModal from "./ContributorModal/ContributorModal";
import Loader from "../common/Loader/Loader";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const actions = { ...actionsNav, ...actionsContributors };

const url =
	"https://zeipt.us14.list-manage.com/subscribe/post?u=5b6e1a465d466add350763ea7&amp;id=bb727fbf5a";

const SimpleForm = () => <MailchimpSubscribe url={url} />;

class Contributors extends Component {
	state = {
		showModal: false,
		modalContent: null,
		joinUs: false,
		activeSection: ["shop", "get"],
		activeSecondSection: ["payment", "cashier"],
		activeThirdSection: ["project", "network"]
	};

	componentDidMount() {
		window.scrollTo(0, 0);

		if (!this.props.contributors.contributors) {
			this.props.fetchContributors();
		}
		this.props.navOnScroll(true);

		if (window.innerWidth <= 768) {
			this.setState({ activeSection: ["shop"] });
			this.setState({ activeSecondSection: ["cashier"] });
			this.setState({ activeThirdSection: ["project"] });
		}

		/* window.addEventListener("resize", this.resizeHandler); */
		window.addEventListener("click", this.resetInputHandler);
	}

	componentWillUnmount() {
		/* window.removeEventListener("resize", this.resizeHandler); */
		window.removeEventListener("click", this.resetInputHandler);
	}

	resizeHandler = () => {
		if (window.innerWidth <= 768) {
			this.setState({ activeSection: ["shop"] });
			this.setState({ activeSecondSection: ["cashier"] });
			this.setState({ activeThirdSection: ["project"] });
		} else {
			this.setState({ activeSection: ["shop", "get"] });
			this.setState({ activeSecondSection: ["cashier", "payment"] });
			this.setState({ activeThirdSection: ["project", "network"] });
		}
	};

	toggleModal = contributor => {
		console.log(this.props.contributors);
		this.setState({
			showModal: !this.state.showModal,
			modalContent: this.state.modalContent ? null : contributor
		});
	};

	toggleActiveSection = section => {
		this.setState({ activeSection: [section] });
	};
	toggleSecondActiveSection = section => {
		this.setState({ activeSecondSection: [section] });
	};
	toggleThirdActiveSection = section => {
		this.setState({ activeThirdSection: [section] });
	};

	toggleJoinUs = () => {
		this.setState({
			joinUs: !this.state.joinUs
		});
	};

	render() {
		const { loading, contributors } = this.props.contributors;
		const {
			showModal,
			modalContent,
			joinUs,
			activeSection,
			activeSecondSection,
			activeThirdSection
		} = this.state;

		const shopHere =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("shop")
				)
				.map((contributor, i) => (
					<Fragment key={i}>
						<Contributor
							logo={contributor.logo}
							name={contributor.name}
							openModal={() => this.toggleModal(contributor)}
						/>
					</Fragment>
				));

		const getReceipts =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("receive")
				)
				.map((contributor, i) => (
					<Contributor
						key={i}
						logo={contributor.logo}
						name={contributor.name}
						openModal={() => this.toggleModal(contributor)}
					/>
				));

		const distributionPartners =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("distribution")
				)
				.map((contributor, i) => (
					<Contributor
						key={i}
						logo={contributor.logo}
						name={contributor.name}
						openModal={() => this.toggleModal(contributor)}
					/>
				));

		const networkPartners =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("network")
				)
				.map((contributor, i) => (
					<Contributor
						key={i}
						logo={contributor.logo}
						name={contributor.name}
						openModal={() => this.toggleModal(contributor)}
					/>
				));

		const projectPartners =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("project")
				)
				.map((contributor, i) => <Contributor key={i} />);

		const paymentPartners =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("payments")
				)
				.map((contributor, i) => (
					<Contributor
						key={i}
						logo={contributor.logo}
						name={contributor.name}
						openModal={() => this.toggleModal(contributor)}
					/>
				));
		const cashierPartners =
			contributors &&
			contributors
				.filter(
					contributor =>
						contributor.category &&
						contributor.category.includes("cashiers")
				)
				.map((contributor, i) => (
					<Contributor
						key={i}
						logo={contributor.logo}
						name={contributor.name}
						openModal={() => this.toggleModal(contributor)}
					/>
				));

		const joinUsInput = () => {
			return (
				<MailchimpSubscribe
					url={url}
					render={({ subscribe, status, message }) => (
						<div className={style.joinUsWrapper} id="JoinUsSubmit">
							<SimpleForm
								onSubmitted={formData => subscribe(formData)}
							/>
							{status === "sending" && (
								<div style={{ color: "#00519a" }}>
									sending...
								</div>
							)}
							{status === "error" && (
								<div
									style={{ color: "#e52e6a" }}
									dangerouslySetInnerHTML={{
										__html: message
									}}
								/>
							)}
							{status === "success" && (
								<div style={{ color: "#3cbf71" }}>
									Subscribed !
								</div>
							)}
						</div>
					)}
				/>
			);
		};

		const activeTypeClass = [style.type, style.activeType].join(" ");

		// Display loader while fetching data
		if (loading) return <Loader />;

		return (
			<div className={style.contributors}>
				{showModal && (
					<ContributorModal
						modalContent={modalContent}
						toggleModal={this.toggleModal}
					/>
				)}
				<div className={style.intro}>
					<div className={style.introContent}>
						<h1>Contributors</h1>
						<h4>
							In order for our project to succeed, we are
							dependent on our network of players. Following are
							the ones working towards a more sustainable retail
							so far.
						</h4>
						<div className={style.joinUsForm}>
							{!joinUs && (
								<button
									id="JoinUsBtn"
									onClick={this.toggleJoinUs}
									className={style.joinBtn}
								>
									Join us
								</button>
							)}
							{joinUs && joinUsInput()}
						</div>
					</div>
				</div>

				<div className={style.content}>
					<div className={style.contentTop}>
						<div className={style.typeWrapper}>
							<h5
								className={
									activeSection.includes("shop")
										? activeTypeClass
										: style.type
								}
								onClick={() => this.toggleActiveSection("shop")}
							>
								Shop here
							</h5>
							<h5
								className={
									activeSection.includes("get")
										? activeTypeClass
										: style.type
								}
								onClick={() => this.toggleActiveSection("get")}
							>
								Get receipts here
							</h5>
						</div>
						{activeSection.includes("shop") && (
							<div className={style.typeContent}>{shopHere}</div>
						)}
						{activeSection.includes("get") && (
							<div className={style.typeContent}>
								{getReceipts}
							</div>
						)}
					</div>

					<div className={style.distribution}>
						<h5>Distribution partner</h5>
						<div className={style.distributionWrapper}>
							{distributionPartners}
						</div>
					</div>

					<div className={style.cashierpayment}>
						<div className={style.contentTop}>
							<div className={style.typeWrapper}>
								<h5
									className={
										activeSecondSection.includes("cashier")
											? activeTypeClass
											: style.type
									}
									onClick={() =>
										this.toggleSecondActiveSection(
											"cashier"
										)
									}
								>
									Cashier
								</h5>
								<h5
									className={
										activeSecondSection.includes("payment")
											? activeTypeClass
											: style.type
									}
									onClick={() =>
										this.toggleSecondActiveSection(
											"payment"
										)
									}
								>
									Payment (PSP)
								</h5>
							</div>

							{activeSecondSection.includes("cashier") && (
								<div className={style.typeContent}>
									{cashierPartners}
								</div>
							)}
							{activeSecondSection.includes("payment") && (
								<div className={style.typeContent}>
									{paymentPartners}
								</div>
							)}
						</div>
					</div>
					<div className={style.cashierpayment}>
						<div className={style.contentTop}>
							<div className={style.typeWrapper}>
								<h5
									className={
										activeThirdSection.includes("project")
											? activeTypeClass
											: style.type
									}
									onClick={() =>
										this.toggleThirdActiveSection("project")
									}
								>
									Project partners
								</h5>
								<h5
									className={
										activeThirdSection.includes("network")
											? activeTypeClass
											: style.type
									}
									onClick={() =>
										this.toggleThirdActiveSection("network")
									}
								>
									Network partners
								</h5>
							</div>

							{activeThirdSection.includes("project") && (
								<div className={style.typeContent}>
									{projectPartners}
								</div>
							)}
							{activeThirdSection.includes("network") && (
								<div className={style.typeContent}>
									{networkPartners}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		contributors: state.contributors
	};
};

export default connect(mapStateToProps, actions)(Contributors);
