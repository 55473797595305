import React from 'react';
import style from './index.module.scss';
import {Link} from 'react-router-dom';
import ErrorImg from '../../../assets/404.svg'

const Error = () => {
    return (
        <div className={style.subError}>
            <h3 className={style.heading}>This page doesnt exists</h3>
            <p className={style.quote}>Just like paper receipts should not...</p>
            <Link to='/research' className={style.btn}>Research Page</Link>
            <img src={ErrorImg} alt="error" className={style.img}/>
        </div>
    )
}

export default Error
