import React, { Component } from "react";
import style from "./index.module.scss";
import tower from "../../../assets/research/empire_state.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions/research";
import Accordion, { Accordions, Small } from "../Accordion";

class Water extends Component {
	state = {
		activeArrow: false
	};

	goToNextPage = () => {
		this.props.nextPage("co2");
		window.scrollTo(0, this.props.research.height);
	};

	render() {
		return (
			<div className={style.water} id="water">
				<h3 className={style.heading}>
					A significant amount of water is wasted
				</h3>
				<Accordions>
					<Accordion
						design={"big"}
						title={"186"}
						description={
							"Empire State Buildings filled with water per year"
						}
						image={tower}
						subtitle={"Paper receipts costs our planet"}
					>
						<h6>
							Number of tons of thermal paper used for paper
							receipts each year
						</h6>
						<b>2,2 million tons</b>

						<h6>
							Liters of water used per ton thermal paper produced
						</h6>
						<b>88 578 liters</b>

						<h6>
							Liters of water used annually for producing paper
							receipts
						</h6>
						<Small>(2 200 000 * 88 578)</Small>
						<b>194 871 600 000 liters of water</b>

						<h6>Volume of Empire State Builing</h6>
						<Small>
							(37 million cubic feet * 28,32 liters of water)
						</Small>
						<b>1 047 840 000 liters of water</b>

						<h6>
							Annual water cost of receipts production per Empire
							State Building
						</h6>
						<Small>
							(194 871 600 000 / 1 047 840 000 liters of water)
						</Small>
						<b>186 Empire State Buildings</b>
					</Accordion>
				</Accordions>

				<strong className={style.paragraphHeading}>
					Why is wasting water a problem?
				</strong>
				<p className={style.paragraph}>
					Less than 1% of the world’s worlds water is available for
					humans to consume, water that is essential for our survival.
					Unfortunately, with the overuse of freshwater, our supply
					has become increasingly scarce. Limited water supplies
					impacts our ability to cook, clean, grow and most
					essentially drink. Because of this, certain parts of the
					world could become prone to an increase in diseases, illness
					and starvation as the water supply continues to decrease..
				</p>
				<p className={style.paragraph}>
					Aside from humans, other species also rely on our
					freshwater. And with the decrease of supply, certain plant
					and animal species will become endangered, which could lead
					to negative consequences for our biosphere.
				</p>
				<strong className={style.paragraphHeading}>
					How does paper receipts contribute to wasting water?
				</strong>
				<p className={style.paragraph}>
					The manufacturing process of thermal paper used for paper
					receipts requires a significant amount of water. To be
					specific, 88 578 liters of water per ton of thermal paper
					produced.
				</p>
				<p className={style.paragraph}>
					If we take into consideration that 3.1 million tons of
					thermal paper was sold globally and 71% went to point of
					sale in 2018, then this equals 196.960.000.000 liters of
					water being wasted in 2018 alone. Enough to fill the Empire
					State Building with water 186 times!
				</p>
				<p className={style.paragraph}>... That’s a lot of water.</p>

				<Link
					className={style.nextPage}
					to="/research/co2"
					onClick={this.goToNextPage}
				>
					<span className={style.link}>Air pollution</span>
				</Link>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(Water);
