import React, { Component } from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actions from "../../../actions/counter";
import natureIcon from "../../../assets/header/icon_nature.svg";
import waterIcon from "../../../assets/header/icon_water.svg";
import co2Icon from "../../../assets/header/icon_co2.svg";
import Odometer from "react-odometerjs";
import "./odometer.scss";

class WeWaste extends Component {
	state = {
		show: false,
		treesNr: 10000,
		waterNr: 10000000,
		co2Nr: 1000000,
		timeSinceEntered: 0
	};

	// Declaring interval ID's so we can remove them later on component unmount
	intervalTreesId = 0;
	intervalWaterId = 0;
	intervalCO2Id = 0;
	interval = 0;
	timeout = 0;
	timeout2 = 0;
	componentDidMount() {
		// Checking if there is an unmount time (is it first unmount) and if the count is more than 0
		if (
			this.props.count.unmountTime !== null &&
			this.props.count.trees > 10000
		) {
			const curTime = new Date().getTime();

			// Get time diff between unmount and mount times in seconds
			const timeDiff = Math.floor(
				(curTime - this.props.count.unmountTime) / 1000
			);
			const newSeconds = Math.floor(this.props.count.seconds + timeDiff);

			let newCountTrees;
			let newCountWater;
			let newCountCO2;

			if (Math.floor(newSeconds * 1.68 + 10000) > 19988) {
				newCountTrees = 19999;
			} else {
				newCountTrees = Math.floor(newSeconds * 1.68 + 10000);
			}

			if (Math.floor(newSeconds * 6180 + 10000000) > 19962919) {
				newCountWater = 19999999;
			} else {
				newCountWater = Math.floor(newSeconds * 6180 + 10000000);
			}

			if (Math.floor(newSeconds * 599 + 1000000) > 1996405) {
				newCountCO2 = 1999999;
			} else {
				newCountCO2 = Math.floor(newSeconds * 599 + 1000000);
			}

			// antall siffer dele på økning per sek
			this.setState({
				treesNr: newCountTrees,
				waterNr: newCountWater,
				co2Nr: newCountCO2,
				timeSinceEntered: newSeconds
			});
		}

		//START AFTER 3 sec,

		this.timeout = setTimeout(() => {
			this.setState(prevState => ({
				treesNr:
					prevState.treesNr > 19988
						? 19999
						: (prevState.treesNr += 1.68 * 6),
				waterNr:
					prevState.waterNr > 19962919
						? 19999999
						: (prevState.waterNr += 6180 * 6),
				co2Nr:
					prevState.co2Nr > 1996405
						? 1999999
						: (prevState.co2Nr += 599 * 6)
			}));

			this.timeout2 = setTimeout(() => {
				this.setState(prevState => ({
					timeSinceEntered: (prevState.timeSinceEntered += 6)
				}));
			}, 3000);

			this.intervalTreesId = setInterval(() => {
				this.setState(prevState => ({
					treesNr:
						prevState.treesNr > 19988
							? 19999
							: (prevState.treesNr += 1.68 * 6)
				}));
				if (this.state.treesNr === 19999) {
					clearInterval(this.intervalTreesId);
				}
			}, 6000);

			this.intervalWaterId = setInterval(() => {
				this.setState(prevState => ({
					waterNr:
						prevState.waterNr > 19962919
							? 19999999
							: (prevState.waterNr += 6180 * 6)
				}));
				if (this.state.waterNr === 19999999) {
					clearInterval(this.intervalWaterId);
				}
			}, 6000);

			this.intervalCO2Id = setInterval(() => {
				this.setState(prevState => ({
					co2Nr:
						prevState.co2Nr > 1996405
							? 1999999
							: (prevState.co2Nr += 599 * 6)
				}));
				if (this.state.co2Nr === 1999999) {
					clearInterval(this.intervalCO2Id);
				}
			}, 6000);

			this.interval = setInterval(() => {
				setTimeout(() => {
					this.setState(prevState => ({
						timeSinceEntered: (prevState.timeSinceEntered += 6)
					}));
				}, 3000);
			}, 6000);
		}, 3000);

		// Start interval count for all three counters with different intervals
		/* this.intervalTreesId = setInterval(
            () =>
                this.setState(prevState => ({
                    treesNr: (prevState.treesNr += 1.68 * 6),
                    waterNr: (prevState.waterNr += 6180 * 6),
                    co2Nr: (prevState.waterNr += 598.99 * 6),
                    timeSinceEntered: (prevState.timeSinceEntered += 6)
                })),
            6000
        ); */

		/* this.intervalWaterId = setInterval(
            () => this.countHandler("waterNr"),
            432
        );
        this.intervalCO2Id = setInterval(() => this.countHandler("co2Nr"), 14); */
	}

	componentWillUnmount() {
		// Send current counts numbers and unmount time to redux so we can use it later to calculate difference on next mount
		this.props.incrementCounters(
			this.state.treesNr,
			this.state.waterNr,
			this.state.co2Nr,
			new Date().getTime(),
			this.state.timeSinceEntered
		);

		// Clear all interval so it wouldn't give any errors
		clearInterval(this.interval);
		clearInterval(this.timeout);
		clearInterval(this.timeout2);
		clearInterval(this.intervalTreesId);
		clearInterval(this.intervalCO2Id);
		clearInterval(this.intervalWaterId);
	}

	// Functions that go into setInterval and increments the numbers in state
	countHandler = countName => {
		this.setState(prevState => ({
			[countName]: prevState[countName] + 1.68
		}));
	};

	render() {
		//__Dest
		const { activeLang, weWaste } = this.props.lang;

		return (
			<section className={style.weWaste}>
				<h5 className={style.heading}>
					Paper receipts have cost our planet
				</h5>
				<div className={style.wastePart}>
					<img
						className={style.icon}
						src={natureIcon}
						alt="nature icon"
					/>
					<div className={style.headingNature}>
						<p>{this.state.treesNr === 19999 ? ">" : ""}</p>
						<Odometer value={this.state.treesNr} format="( ddd)" />
					</div>
					<h5 className={style.desc}>{weWaste.trees[activeLang]}</h5>
				</div>
				<div className={style.wastePart}>
					<img
						className={style.icon}
						src={waterIcon}
						alt="water icon"
					/>
					<div className={style.headingWater}>
						<p>{this.state.waterNr === 19999999 ? ">" : ""}</p>
						<Odometer value={this.state.waterNr} format="( ddd)" />
					</div>
					<h5 className={style.desc}>{weWaste.water[activeLang]}</h5>
				</div>
				<div className={style.wastePart}>
					<img className={style.icon} src={co2Icon} alt="co2 icon" />
					<div className={style.headingCO2}>
						<p>{this.state.co2Nr === 1999999 ? ">" : ""}</p>
						<Odometer value={this.state.co2Nr} format="( ddd)" />
					</div>
					<h5 className={style.desc}>{weWaste.co2[activeLang]}</h5>
				</div>
				<h5 className={style.quote}>
					... since you entered our webpage{" "}
					<b>{this.state.timeSinceEntered} seconds</b> ago.
				</h5>
				<p className={style.small}>
					Powered by Environmental Paper Network's{" "}
					<a
						href="https://c.environmentalpaper.org/"
						rel="noopener noreferrer"
						target="_blank"
					>
						Paper Calculator
					</a>
				</p>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		lang: state.lang,
		count: state.count
	};
};

export default connect(mapStateToProps, actions)(WeWaste);
