import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

// Example URL
// https://apexcharts.com/react-chart-demos/bar-charts/grouped/

class BarChart extends Component {
	state = {
		dummySeries: [
			{
				name:
					"% of the respondents thinking these receipts are important",
				data: [0, 0, 0, 0, 0, 0]
			}
		],
		series: [
			{
				name:
					"% of the respondents thinking these receipts are important",
				data: [92.1, 77.8, 56.6, 56, 44.7, 32.8]
			}
		],
		options: {
			legend: {
				showForSingleSeries: true,
				position: "right",
				width: 100
			},
			chart: {
				type: "bar",
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: true,
					dataLabels: {
						position: "top"
					}
				}
			},
			dataLabels: {
				offsetX: -5,
				style: {
					fontSize: "12px",
					colors: ["white"]
				}
			},
			colors: ["#00519A"],
			stroke: {
				width: 1,
				colors: ["#fff"]
			},
			xaxis: {
				categories: [
					"Elektronics",
					"Furniture",
					"Sports",
					"Fashion",
					"Hotel & accommodation",
					"Transport"
				]
			}
		}
	};

	componentDidMount() {
		this.resizeHandler();
		window.addEventListener("resize", this.resizeHandler);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeHandler);
	}

	resizeHandler = () => {
		const legend = { ...this.state.options.legend };
		const plotOptions = { ...this.state.options.plotOptions };

		if (window.innerWidth <= 600) {
			legend.position = "top";
			legend.width = "100%";
			plotOptions.bar.barHeight = "100%";
			this.setState({
				options: {
					legend,
					plotOptions
				}
			});
		} else {
			legend.position = "right";
			legend.width = 100;
			plotOptions.bar.barHeight = "70%";
			this.setState({
				options: {
					legend,
					plotOptions
				}
			});
		}
	};

	render() {
		return (
			<div style={{ width: "100%" }}>
				<ReactApexChart
					options={this.state.options}
					series={
						this.props.showChart
							? this.state.series
							: this.state.dummySeries
					}
					type="bar"
				/>
			</div>
		);
	}
}

export default BarChart;
