import React, { Component } from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../actions/research";
import BarChart from "./BarChart/BarChart";
import SecondBarChart from "./SecondBarChart/SecondBarChart";
import VisibilitySensor from "react-visibility-sensor";

class People extends Component {
	state = {
		showCharts: [false, false]
	};

	onChartVisible = (isVisible, index) => {
		const showCharts = [...this.state.showCharts];
		showCharts[index] = true;

		if (isVisible) {
			this.setState({ showCharts });
		}
	};

	goToNextPage = () => {
		this.props.nextPage("finance");
		window.scrollTo(0, this.props.research.height);
	};

	render() {
		const goodNumberClass = [style.factNumber, style.good].join(" ");
		const invisibleNumber = [style.factNumber, style.invisible].join(" ");
		const goodFactDescClass = [style.factDesc, style.goodFact].join(" ");

		return (
			<div id="people" className={style.globals}>
				<h3 className={style.heading}>The People</h3>
				<p>
					For the past 40 years, physical money has gradually been
					replaced with nifty little plastic cards. Through a quick
					swipe or touch, digital payments are easily made.
				</p>
				<p>
					However, what follows is a piece of paper; a receipt. Often
					destined to have a short lifespan, buried in a trash bin
					next to the cash register.
				</p>
				<p>
					We conducted{" "}
					<a
						href="https://docs.google.com/spreadsheets/d/1eb4HTCVBUpyv94GfJ4obIanvjqh8Ra6ZxKZ6MOC9KLY/edit?usp=sharing"
						target="_blank"
						rel="noopener noreferrer"
					>
						a survey
					</a>{" "}
					of roughly 300 Norwegian consumers, with the intention of
					quantifying the experiences we have regarding paper
					receipts.
				</p>
				<strong>A waste and bad user experience</strong>
				<div className={style.factsWrapper}>
					<div className={style.fact}>
						<h2 className={goodNumberClass}>84%</h2>
						<p className={goodFactDescClass}>
							have <b>needed a receipt</b> in the past 12 months,
							for <b>returns, exchanges or expenses</b>.
						</p>
					</div>
					<div className={style.fact}>
						<h2 className={invisibleNumber}>00%</h2>
						<p className={style.factDesc}>... however</p>
					</div>
					<div className={style.fact}>
						<h2 className={style.factNumber}>85%</h2>
						<p className={style.factDesc}>
							say that when they <b>do need paper receipts</b>,
							they're usually already <b>lost, faded or binned</b>
							.
						</p>
					</div>
					<div className={style.fact}>
						<h2 className={style.factNumber}>72%</h2>
						<p className={style.factDesc}>
							of receipts are <b>thrown away unused</b>...
						</p>
					</div>
					<div className={style.fact}>
						<h2 className={style.factNumber}>79%</h2>
						<p className={style.factDesc}>
							The majority thinks that all paper receipts are a{" "}
							<b>waste of paper.</b>
						</p>
					</div>
					<p className={style.factDesc}>
						Based on these statistics, it seems obvious that
						receipts need to be innovated.
					</p>
					<strong>We want a digital solution</strong>
					<div className={style.fact}>
						<h2 className={goodNumberClass}>96%</h2>
						<p className={style.factDesc}>
							say they would prefer to receive{" "}
							<b>all receipts digitally.</b>
						</p>
					</div>
					<p>
						We are confused as to why we are still printing paper
						receipts, and want to see dealers work to digitally
						deliver our purchase information.
					</p>
					<p>
						Asking where they wanted to receive their receipts, the
						respondents voted the following:
					</p>
					<div className={style.chart}>
						<VisibilitySensor
							onChange={isVisible =>
								this.onChartVisible(isVisible, 0)
							}
						>
							<BarChart
								className={style.chart}
								showChart={this.state.showCharts[0]}
							/>
						</VisibilitySensor>
						<p>
							* Collected points (≈ 300 respondents): <br /> 1 =
							Bad &nbsp; • &nbsp; 2 = Less good &nbsp;&nbsp; •
							&nbsp; 3 = Neutral &nbsp; • &nbsp; 4 = Good &nbsp;
							•&nbsp; 5 = Very good &nbsp; • &nbsp; 6 = Perfect
						</p>
					</div>

					<p>
						Apparently, we prefer to get an overview of our receipts
						in the existing solutions that are connected with our
						bank account and payments.
					</p>
					<p>
						Let's look at what receipts we think are important and
						how we value what they can offer.
					</p>

					<strong>High value and business related</strong>
					<p>
						Upon the question of what types of consumer goods and
						services receipts are needed for, our consumers voted
						for the following:
					</p>
					<div className={style.chart}>
						<VisibilitySensor
							onChange={isVisible =>
								this.onChartVisible(isVisible, 1)
							}
						>
							<SecondBarChart
								showChart={this.state.showCharts[1]}
							/>
						</VisibilitySensor>
					</div>
					<p>
						Also on the list with less than 30% votes were (sorted
						in decreasing order) restaurants, medicines and pharmacy
						products, fuel, groceries and hobby.
					</p>

					<p>
						Most of us have been exposed to join a customer loyalty
						program, and often we tend to decline. But what if we
						were offered an additional value?
					</p>

					<div className={style.fact}>
						<h2 className={goodNumberClass}>69%</h2>
						<p className={style.factDesc}>
							say that they are{" "}
							<b>
								more likely to join a customer loyalty program
							</b>{" "}
							if it offers <b>digital receipts</b>
						</p>
					</div>

					<p>Perhaps not so surprising when...</p>

					<div className={style.fact}>
						<h2 className={style.factNumber}>40%</h2>
						<p className={style.factDesc}>
							of the people asked had{" "}
							<b>lost valuables and money</b> the last 12 months
							due to <b>the absence of their receipt</b>.
						</p>
					</div>
					<p>
						Clearly, this study shows that we want a better
						alternative than to store receipts in a shoebox or
						carying it around in our pockets.
					</p>

					<strong>We're not alone</strong>
					<p>
						Don't only take our word for it. Our fellow friends in
						the cause of digitizing,{" "}
						<a
							href="https://www.capgemini.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Capgemini
						</a>
						, just released{" "}
						<a
							href="https://www.capgemini.com/wp-content/uploads/2020/01/Automation-in-Retail_Infographic.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							their own survey
						</a>{" "}
						on the matter.
					</p>

					<p>
						Their survey was conducted with 5 110 consumers across
						North America, Europe and Asia, along with 500 senior
						executives at the director level in retail.
					</p>
					<p>
						The following is a section from{" "}
						<a
							href="https://www.capgemini.com/news/automation-in-retail-stores/?fbclid=IwAR3B1f6D7LZLN_inQa2l4_DE1xkmaTDiIu30HXF4x5g45WEq-lQK_KxQ23g"
							target="_blank"
							rel="noopener noreferrer"
						>
							the summary they have on the survey
						</a>
						:
					</p>
					<div className={style.quote}>
						<i>
							<strong>
								Customers want retailers to link automation to
								sustainability
							</strong>
							<p>
								Retailers and their customers alike see the need
								to link automation to the sustainability agenda.
								Three quarters (75%) of retailers think
								automation can help them offer more sustainable
								and environmentally friendly solutions. This
								reflects a growing consumer desire to shop with
								retailers that can demonstrate green
								credentials. Consumers surveyed said they would
								prefer to shop with retailers who use automation
								to reduce food waste (69%), reduce consumables
								such as printed receipts (63%), improve energy
								efficiency (58%), and provide sustainability
								information regarding products (52%).
							</p>
						</i>
					</div>
				</div>

				<Link
					className={style.nextPage}
					to="/research/finance"
					onClick={this.goToNextPage}
				>
					<span className={style.link}>Financial impact</span>
				</Link>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(People);
