import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import style from "./index.module.scss";
import FocusTrap from "focus-trap-react";
import startIcon from "../../../assets/contributors/star.svg";
import locationIcon from "../../../assets/contributors/location.svg";
import storeIcon from "../../../assets/contributors/store.svg";
import phoneIcon from "../../../assets/contributors/Phone.svg";
import { IoIosClose } from "react-icons/io";

class ContributorModal extends Component {
	componentDidMount() {
		document.querySelector("html").classList.add("scroll-lock");
	}

	componentWillUnmount() {
		document.querySelector("html").classList.remove("scroll-lock");
	}

	onKeyDown = e => {
		if (e.keyCode === 27) {
			this.props.toggleModal();
		}
	};

	onClickOutside = event => {
		if (this.modal && this.modal.contains(event.target)) return;
		this.props.toggleModal();
	};

	render() {
		const {
			name,
			joined,
			logo,
			partnerImage,
			subCategory,
			primarypower,
			userslocations,
			Geographic,
			history,
			address,
			url,
			category
		} = this.props.modalContent;
		const { activeLang } = this.props.lang;
		const { toggleModal } = this.props;

		// Date template for dom
		const newsDateDom = `
        ${new Date(joined).getDate()}/${new Date(joined).getMonth() +
			1} ${new Date(joined).getFullYear()}`;

		// User locations or users for dom
		const userPopulation = () => {
			if (category.includes("shop")) {
				return (
					<div className={style.iconsItem}>
						<img
							className={style.icon}
							src={storeIcon}
							alt="store"
						/>
						<p className={style.iconText}>
							{userslocations
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
							locations
						</p>
					</div>
				);
			} else if (category.includes("receive")) {
				return (
					<div className={style.iconsItem}>
						<img
							className={style.icon}
							src={phoneIcon}
							alt="phone"
						/>
						<p className={style.iconText}>
							{userslocations
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
							users
						</p>
					</div>
				);
			} else {
				return null;
			}
		};

		return ReactDOM.createPortal(
			<FocusTrap>
				<aside
					className={style.modalOverlay}
					onClick={e => this.onClickOutside(e)}
					onKeyDown={e => this.onKeyDown(e)}
				>
					<button
						type="button"
						className={style.modalCloseBtn}
						onClick={toggleModal}
					>
						<IoIosClose />
					</button>
					<div
						className={style.modalContent}
						ref={n => (this.modal = n)}
					>
						<div className={style.modalHeader}>
							<div className={style.logoWrapper}>
								<img
									alt={name}
									className={style.logoImg}
									src={
										"https://cdn.sanity.io/images/cvlwdgzk/news/" +
										logo.asset._ref.split("-")[1] +
										"-" +
										logo.asset._ref.split("-")[2] +
										"." +
										logo.asset._ref.split("-")[3] +
										"?h=120"
									}
								/>
							</div>
							<div className={style.rightSideWrapper}>
								<div className={style.dateWrapper}>
									<p className={style.joinedText}>
										Joined us
									</p>
									<p className={style.joinedDate}>
										{newsDateDom}
									</p>
								</div>
								<button
									type="button"
									className={style.mobileCloseBtn}
									onClick={toggleModal}
								>
									<IoIosClose />
								</button>
							</div>
						</div>

						<div className={style.photoContainer}>
							<div className={style.photoWrapper}>
								<img
									alt={name}
									className={style.photo}
									src={
										"https://cdn.sanity.io/images/cvlwdgzk/news/" +
										partnerImage.asset._ref.split("-")[1] +
										"-" +
										partnerImage.asset._ref.split("-")[2] +
										"." +
										partnerImage.asset._ref.split("-")[3] +
										"?h=200"
									}
								/>
							</div>
							{subCategory && (
								<div className={style.status}>
									{subCategory}
								</div>
							)}
						</div>

						<div className={style.iconsWrapper}>
							<div className={style.iconsItem}>
								<img
									className={style.icon}
									src={startIcon}
									alt="star icon"
								/>
								<p className={style.iconText}>
									{primarypower[activeLang]}
								</p>
							</div>

							{userPopulation()}

							<div className={style.iconsItem}>
								<img
									className={style.icon}
									src={locationIcon}
									alt="star icon"
								/>
								<p className={style.iconText}>
									{Geographic[activeLang]}
								</p>
							</div>
						</div>

						<div className={style.aboutWrapper}>
							<p className={style.about}>{history[activeLang]}</p>
							<a
								className={style.address}
								href={`http://maps.google.com/?q=${address.text}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{address.text}
							</a>
							<a
								className={style.link}
								href={url}
								target="_blank"
								rel="noopener noreferrer"
							>
								{url}
							</a>
						</div>
					</div>
				</aside>
			</FocusTrap>,
			document.body
		);
	}
}

const mapStateToProps = state => {
	return {
		lang: state.lang
	};
};

export default connect(mapStateToProps)(ContributorModal);
