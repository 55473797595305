import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import style from "./Footer.module.scss";

import Leaves from "../../assets/leaves.svg";

import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaMediumM,
} from "react-icons/fa";

function Footer() {
  const language = useSelector((state) => state.activeLang);

  return (
    <>
      <div className={style.contentWrapper}>
        <div className={style.wrapper}>
          <div className={style.column1}>
            {language === "no" ? <h2>Lenker</h2> : <h2>Links</h2>}
            {language === "no" ? (
              <div>
                <Link to="/about">The Team</Link>
                <Link to="/price">Priser</Link>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <a href="https://thezeiptproject.com/#/research">
                    The Research
                  </a>
                  <div className={style.imgWrapper}>
                    <img className={style.leaves} src={Leaves} alt="" />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <a href="https://thezeiptproject.com/#/">The Zeipt Project</a>
                  <div className={style.imgWrapper}>
                    <img className={style.leaves} src={Leaves} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <a href="https://zeipt.com/#/">Zeipt.com</a>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <a href="https://zeipt.com/#/about">The Team</a>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <a href="https://thezeiptproject.com/#/research">
                    The Research
                  </a>
                  <div className={style.imgWrapper}>
                    <img className={style.leaves} src={Leaves} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={style.column2}>
            {language === "no" ? (
              <h2>Sosiale medier</h2>
            ) : (
              <h2>Social media</h2>
            )}

            <div className={style.iconWrapper}>
              <a
                href="https://www.facebook.com/zeipt"
                target="_blank"
                rel="noopener noreferrer"
                className={style.icon}
              >
                <FaFacebookF />
              </a>
              <a
                href="https://www.linkedin.com/company/zeipt"
                target="_blank"
                rel="noopener noreferrer"
                className={style.icon}
              >
                <FaLinkedinIn />
              </a>
              <a
                href="https://www.twitter.com/zeipt"
                target="_blank"
                rel="noopener noreferrer"
                className={style.icon}
              >
                <FaTwitter />
              </a>
              <a
                href="https://www.instagram.com/thezeipt/"
                target="_blank"
                rel="noopener noreferrer"
                className={style.icon}
              >
                <FaInstagram />
              </a>
              <a
                href="https://zeipt.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={style.icon}
              >
                <FaMediumM />
              </a>
            </div>
          </div>
          <div className={style.column3}>
            <div className={style.detailsWrapper}>
              {language === "no" ? <h2>Zeipt As</h2> : <h2>Zeipt As</h2>}
              <p>Org. nr: 916 809 026</p>
              <p className={style.address}>Øvre Slottsgate 18, 0157 Oslo</p>
              <p>Norge</p>
              <p>contact@zeipt.com</p>
            </div>
          </div>
        </div>
        <div className={style.copyright}>
          <p>© Zeipt AS 2021</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
