import * as types from './types';
import sanityClient from '@sanity/client';

// Init client
const client = sanityClient({
    projectId: "cvlwdgzk",
    dataset: "news",
    useCdn: true
});

// Query for contributors
const contributorsQuery = `*[_type == "contributors"] {
_id,
name,
logo,
category,
subCategory,
joined,
status,
primarypower,
url,
userslocations,
Geographic,
address,
history,
partnerImage
}`;

// Fetch all contributors
export function fetchContributors ()  {
    return async function(dispatch){
        // SET LOADING
        dispatch({type: types.FETCHING_CONTRIBUTORS});
        try{
            const contributors = await client
                .fetch(contributorsQuery)
                .then(res => {
                    return res
                })
            dispatch({
                type: types.FETCH_CONTRIBUTORS,
                contributors
            })
        } catch(e){
            console.log(e.response);
        }
    }
}
