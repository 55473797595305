import React from 'react';
import style from '../index.module.scss';
import {connect} from 'react-redux' 
import * as actions from '../../../../actions/posts';

const ProgressBar = (props) => {
    return (
        <div className={style.progressBar}>
            <div className={style.bar} style={{left: props.sliderScrolled + '%'}}></div>
        </div>
    )
};

const mapStateToProps = state => {
    return  {
        posts: state.posts
    }
}
export default connect(mapStateToProps, actions)(ProgressBar);