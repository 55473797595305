import React, { Component } from "react";
import style from "./index.module.scss";
import PostLabel from "./PostLabel/PostLabel";
import ProgressBar from "./ProgressBar/ProgressBar";
import PostModal from "./PostModal/PostModal";
import { connect } from "react-redux";
import * as actions from "../../../actions/posts";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import ReactHtmlParser from "react-html-parser";
import Lottie from "react-lottie";
import animationData from "../../../assets/zeiptprojectlottie/data";
import leaves from '../../../assets/header/leaves.svg';

//__Lottie options
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMaxYMid"
    }
};

class Header extends Component {
    //__Refs
    slider = React.createRef();

    state = {
        sliderScrolled: 0,
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        this.slider.current.scrollLeft = this.slider.current.scrollWidth - this.slider.current.clientWidth;

        // this.testScroll();
    }

    //__Click scroll slider left
    scrollLeft = () => {
        const { postsPerPage } = this.props.posts;
        // Animate scroll left
        let scrollAmount = 0;
        const slider = this.slider.current;
        const slideTimer = setInterval(function() {
            slider.scrollLeft -= 50;
            scrollAmount += 10;
            if (scrollAmount >= slider.clientWidth / postsPerPage) {
                window.clearInterval(slideTimer);
            }
        }, 20);
    };

    //__Click scroll slider left
    scrollRight = () => {
        const { postsPerPage } = this.props.posts;
        // Animate scroll right
        let scrollAmount = 0;
        const slider = this.slider.current;
        const slideTimer = setInterval(function() {
            slider.scrollLeft += 50;
            scrollAmount += 10;
            if (scrollAmount >= slider.clientWidth / postsPerPage) {
                window.clearInterval(slideTimer);
            }
        }, 20);
    };

    // Get the ratio of width/scroll 0/1 to use for progress bar update
    sliderScrolled = () => {
        const width =
            this.slider.current.scrollWidth - this.slider.current.clientWidth;
        const sliderScrolled = (
            (this.slider.current.scrollLeft / width) *
            100
        ).toFixed();
        this.setState({ sliderScrolled });
    };

    render() {
        // Dest
        const { activeLang, landingPage } = this.props.lang;
        const { sliderScrolled } = this.state;


        // Splitting heading into words for span coloring
        const firstWord = landingPage.heading[activeLang].split(" ");
        const otherWords = firstWord.slice(1).join(" ");

        const currentPostsDom =
            this.props.posts.allPosts &&
            this.props.posts.allPosts.map(post => {
                return <PostLabel key={post._id} post={post} />;
            });

        const lineLength =
            this.props.posts.allPosts && this.props.posts.allPosts.length + 1;

        return (
            <div className={style.header}>
                {this.props.posts.singlePost && <PostModal />}

                <div className={style.leavesWrapper}><img className={style.sideLeaves} src={leaves} alt=""/></div>


                <div className={style.lottieOverlay}></div>
                <div className={style.imgContainer}>
                    <Lottie
                        options={defaultOptions}
                        style={{
                            pointerEvents: "none",
                            textAlign: "right"
                        }}
                    />
                </div>

                <div className={style.headingWrapper}>
                    <h4 className={style.quote}>
                        {ReactHtmlParser(landingPage.quote1[activeLang])}
                    </h4>
                    <h1 className={style.heading}>
                        <span className={style.span}>{firstWord[0]}</span>
                        <br />
                        {otherWords}
                        <span className={style.span}></span>
                    </h1>
                    {/*<h4 className={style.quote}>*/}
                    {/*    {ReactHtmlParser(landingPage.quote2[activeLang])}*/}
                    {/*</h4>*/}
                </div>
                <div
                    className={style.sliderWrapper}
                    onScroll={this.sliderScrolled}
                >
                    <div className={style.sliderContainer}>
                        <div
                            onClick={this.scrollLeft}
                            className={
                                sliderScrolled <= 0
                                    ? [style.left, style.inactiveArrow].join(
                                          " "
                                      )
                                    : style.left
                            }
                        >
                            <FiChevronLeft />
                        </div>
                        <div className={style.timeLine} ref={this.slider}>
                            <div
                                className={style.line}
                                style={{ gridColumn: `1 / ${lineLength}` }}
                            ></div>
                            {currentPostsDom}
                        </div>
                        <div
                            onClick={this.scrollRight}
                            className={
                                sliderScrolled >= 100
                                    ? [style.right, style.inactiveArrow].join(
                                          " "
                                      )
                                    : style.right
                            }
                        >
                            <FiChevronRight />
                        </div>
                    </div>
                </div>
                <ProgressBar sliderScrolled={sliderScrolled} />
                <div className={style.sliderBackground}></div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        posts: state.posts,
        lang: state.lang
    };
};

export default connect(mapStateToProps, actions)(Header);
