import * as types from '../actions/types';

const initialState = {
   contributors: null,
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_CONTRIBUTORS: {
            return {
                contributors: action.contributors,
                loading: false
            };
        }
        case types.FETCHING_CONTRIBUTORS: {
            return {
                ...state,
                loading: true
            }
        }

        default:
            return state;
    }
};