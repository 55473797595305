import * as types from "./types";

export function incrementCounters(
	countTrees,
	countWater,
	countCO2,
	date,
	seconds
) {
	return {
		type: types.INCREMENT_COUNTER,
		countTrees,
		countWater,
		countCO2,
		date,
		seconds
	};
}
