import React, { Component } from "react";
import style from "./index.module.scss";
import animal from "../../../assets/research/animal.svg";
import flood from "../../../assets/research/flood.svg";
import trees from "../../../assets/research/trees.svg";
import wheat from "../../../assets/research/wheat.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions/research";
import Accordion, { Accordions, Small } from "../Accordion";

class Trees extends Component {
	state = {
		activeArrow: [
			{ id: 1, active: false },
			{ id: 2, active: false }
		]
	};

	toggleActiveArrow = i => {
		const activeArrowArray = [...this.state.activeArrow];
		activeArrowArray[i].active = !activeArrowArray[i].active;
		this.setState({ activeArrow: activeArrowArray });
	};

	goToNextPage = () => {
		this.props.nextPage("water");
		window.scrollTo(0, this.props.research.height);
	};

	render() {
		/* //__Combined classes
		const activeArrowClass = [style.icon, style.activeIcon].join(" ");
		const activeTreesCutClass = [style.treesCut, style.activeTreesCut].join(
			" "
		); */

		return (
			<div id="trees" className={style.trees}>
				<h3>Trees are cut down in a record speeds</h3>

				<Accordions>
					<Accordion
						design={"big"}
						title={"53 460 000"}
						description={"trees per year"}
						image={trees}
						subtitle={"Paper receipts costs our planet"}
					>
						<h6>
							Number of tons of thermal paper used for paper
							receipts each year
						</h6>
						<b>2,2 million tons</b>

						<h6>
							Number of trees used per ton thermal paper produced
						</h6>
						<b>24,3 trees</b>

						<h6>Trees cut annually for producing paper receipts</h6>
						<Small>(2 200 000 * 24,3)</Small>
						<b>53,46 million trees</b>
					</Accordion>
				</Accordions>

				<strong>Why deforestation is a problem?</strong>
				<p>
					To understand why the removal of trees is a problem, we need
					to start with the very basics and why it should be of global
					concern:
				</p>
				<p>
					Deforestation is concentrated in the most biologically
					diverse and carbon-rich habitats: the loss of natural forest
					continues to run at the unsustainable pace of 7.6 million
					hectares per year. And while annual forest loss was 0.13% in
					2015 given re-plantation of trees, these areas are
					considered managed forest and lack the biodiversity of
					natural forests.
				</p>
				<p>
					And the trend is pointing in the wrong direction with
					natural forests decreasing more quickly, at 0.24% yearly.
					According to satellite-based monitoring, deforestation
					increased 9% in 2011–2014 compared to the 2001–2010, notably
					driven by increase of natural forest loss in Brazil.
				</p>
				<p>
					Aside from land clearance the main problems that arise with
					deforestation are:
				</p>
				{/* <p>
					You might have heard the expression{" "}
					<i>"Trees are the lungs of the earth"</i>. But what does
					this expression really mean? We all know that oxygen is
					essential for human survival, as this is what allows for us
					to breathe normally. Without oxygen, there's no life... CO2
					is the counterpart of oxygen, and if you try breathing CO2,
					you will suffocate, and die.
				</p>
				<p>
					Now, when humans breathe oxygen, we exhale CO2. Trees on the
					other hand breathe CO2, and exhale oxygen... In a perfect
					world where humans don't interfafe with nature, this causes
					a balance that brings harmony to our exosystem.
				</p>
				<p>
					On top of an increased CO2 production, the very things that
					help reduce these emissions are being chopped down in favor
					of clearing land for business purposes, as well as producing
					goods like building materials, consumer goods, and notably
					paper receipts.
				</p>
				<p>
					Unfortunately, the world isn't perfect. And with an increase
					in CO2 emissions from electricity production,
					transportation, agriculture and other industries - this
					balance is quickly shifting in the wrong direction.
				</p>
				<p>
					On top of an increased CO2 production, the very things that
					help reduce these emissions are being chopped down in favor
					of clearing land for business purposes, as well as producing
					goods like building materials, consumer goods, and notably
					paper receipts.
				</p>
				<p>
					In fact, the consumption of paper receipts in the United
					States alone, equates to more than 9.6 million trees being
					chopped down every year. On a global basis this number is
					expected to be almost three times as high. This equates to a
					forest almost the size of Singapore, disappearing on a
					yearly basis in the favor of paper receipts.
				</p>
				<p>
					Aside from CO2, other problems that arise with deforestation
					are:
				</p> */}
				<ul className={style.list}>
					<li className={style.item}>
						<div className={style.icon}>
							<img src={animal} alt="animal icon" />
						</div>
						<p className={style.itemText}>
							Loss of habitat, causing certain animal and plant
							species to go extinct.
						</p>
					</li>
					<li className={style.item}>
						<div className={style.icon}>
							<img src={wheat} alt="wheat icon" />
						</div>
						<p className={style.itemText}>
							Water becomes increasingly less present in the
							atmosphere, causing soils to become dryer, which in
							turn makes growing crops for food more difficult.
						</p>
					</li>
					<li className={style.item}>
						<div className={style.icon}>
							<img src={flood} alt="flood icon" />
						</div>
						<p className={style.itemText}>
							As more trees are being removed, the soil beneath
							erodes, causing farmers to move to new locations to
							repeat the cycle. The land left behind then becomes
							more susceptible to flooding.
						</p>
					</li>
				</ul>
				<p></p>
				<p>
					Now, when humans breathe oxygen, we exhale CO2. Trees on the
					other hand breathe CO2, and exhale oxygen... In a perfect
					world where humans don't interfafe with nature, this causes
					a balance that brings harmony to our exosystem.
				</p>
				<p>
					On top of an increased CO2 production, the very things that
					help reduce these emissions are being chopped down in favor
					of clearing land for business purposes, as well as producing
					goods like building materials, consumer goods, and notably
					paper receipts.
				</p>

				<p>
					In addition to this, trees are still the most effective
					natural storage of CO2 known to mankind. To illustrate: When
					humans breathe oxygen (which also means that we conduct
					industries), we exhale CO2. It's just part of living. Trees,
					on the other hand, inhale CO2 and use it as building
					material, storing it away from the atmosphere around it.
				</p>
				<Link
					className={style.nextPage}
					to="/research/water"
					onClick={this.goToNextPage}
				>
					<span className={style.link}>Water consumption</span>
				</Link>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(Trees);
