import React from 'react';
import style from './index.module.scss';

const Loader = () => {
    return (
        <div className={style.loader}>
            <div className={style.ldsEllipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader
