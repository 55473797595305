import React, { Component } from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions/research";
import Accordion, { Accordions, Small } from "../Accordion";

class Nature extends Component {
	goToNextPage = page => {
		this.props.nextPage(page);
		window.scrollTo(0, this.props.research.height);
	};

	render() {
		return (
			<div id="nature" className={style.trees}>
				<h3>Receipts' impact on our planet</h3>

				<Accordions>
					<Accordion
						design={"big"}
						subtitle={"Paper receipts are annually made of"}
						explainer={
							"* affecting a land area equal to a tenth of Belgium yearly"
						}
						title={
							<>
								2,2M <i>tons</i>
							</>
						}
						description={"of thermal paper"}
					>
						<h6>Tons of thermal paper sold globally in 2018</h6>
						<b>3.1 million tons</b>

						<h6>
							Percentage of thermal paper going to POS (Point of
							sale)
						</h6>
						<b>71 %</b>
					</Accordion>
					<Accordion
						design={"big"}
						subtitle={"Thermal paper has an average of"}
						title={"8%"}
						description={"recycling rate"}
						explainer={
							<>
								* Worst recycling rate of all paper types (
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://environmentalpaper.org/wp-content/uploads/2018/04/StateOfTheGlobalPaperIndustry2018_FullReport-Final.pdf"
								>
									source
								</a>
								)
							</>
						}
					>
						<table>
							<thead>
								<tr>
									<th>Paper grade</th>
									<th>% of world production</th>
									<th>Avg. % of recycled pulp</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										Paperboard, Wrapping & Packaging Paper
									</td>
									<td>57%</td>
									<td>56%</td>
								</tr>
								<tr style={{ color: "#E52E6A" }}>
									<td>Printing & Writing</td>
									<td>25%</td>
									<td>8%</td>
								</tr>
								<tr>
									<td>Sanitary & Household Tissue</td>
									<td>8%</td>
									<td>34%</td>
								</tr>
								<tr>
									<td>Newsprint</td>
									<td>6%</td>
									<td>68%</td>
								</tr>
								<tr>
									<td>Other</td>
									<td>4%</td>
									<td>27%</td>
								</tr>
							</tbody>
						</table>
					</Accordion>
				</Accordions>
				<p>
					Just as the energy, metal and cement industry, the pulp and
					paper manufacturing industry is one of the pillars of a
					modern way of living and is subsequently also one of the
					world’s biggest polluters. The sector is not only the fifth
					largest consumer of energy, accounting for 4% of all the
					world’s energy use, but the process of paper uses more water
					to produce one ton of product than perhaps any other
					industry. On average 10 litres of water are required to make
					one A4 sheet of paper – in some cases, it’s as high as 20
					litres.
				</p>
				<p>
					And paper receipts happens to also be one of the worst paper
					products in terms of sustainability, as it is made from
					something called chemical kraft pulp, which is used for
					thermal paper. Due to its chemical nature of its production,
					it is exceptionally bad to reprocess in the recycling
					aspect. Although the pulp and paper industry are moving in
					the right direction with as much as 56% recycled grade of
					paper in some of its products, the receipt is considered to
					have no more than 8% (conservatively calculated).
				</p>
				<p>
					To this fact, wood used for receipt production alone is
					calculated to be 2.2 million tonnes and affecting a land
					area equal to a tenth of Belgium yearly (3562,9 km2)*.
				</p>
				<Small style={{ marginTop: -40 }}>
					*Market numbers from 2018.
				</Small>
				<Link
					className={style.nextPage}
					to="/research/trees"
					onClick={() => this.goToNextPage("trees")}
				>
					<span className={style.link}>Waste of trees</span>
				</Link>

				<Link
					className={style.nextPage}
					to="/research/water"
					onClick={() => this.goToNextPage("water")}
				>
					<span className={style.link}>Water consumption</span>
				</Link>
				<Link
					className={style.nextPage}
					to="/research/co2"
					onClick={() => this.goToNextPage("co2")}
				>
					<span className={style.link}>Air pollution</span>
				</Link>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(Nature);
