import React, { Component } from "react";
import style from "./index.module.scss";
import economic from "../../../assets/research/icon_economic.svg";
import nature from "../../../assets/research/nature.svg";
import natureMobile from "../../../assets/research/more-nature.svg";
import trees from "../../../assets/research/icon_nature.svg";
import toxic from "../../../assets/research/icon_toxic.svg";
import people from "../../../assets/research/icon_people.svg";
import water from "../../../assets/research/icon_water.svg";
import co2 from "../../../assets/research/icon_co2.svg";
import sources from "../../../assets/research/sources.svg";
import { IoIosClose } from "react-icons/io";
import { connect } from "react-redux";
import * as actionsNav from "../../../actions/nav";
import * as actionsResearch from "../../../actions/research";

import { Link, withRouter } from "react-router-dom";

const actions = { ...actionsNav, ...actionsResearch };

class SideNav extends Component {
	state = {
		navClass: style.sideNav,
		prevScroll: 0,
		activeSideNavItem: "",
		subNav: false
	};

	toggleActiveSideNavItem = urlName => {
		// Pass name of the active nav item for active class
		this.props.nextPage(urlName);

		// Scroll window to the position of content top
		window.scrollTo(0, this.props.research.height);
	};

	toggleSubNav = () => {
		this.setState({subNav: !this.state.subNav})
	};

	render() {
		//__DEST
		const { navClass, subNav } = this.state;

		//__Active css classes
		const activeNavItemClass = [style.item, style.activeNavItem].join(' ');
		const subNavActive = [style.subNav, style.subNavShow].join(' ');

		return (
			<nav className={navClass}>
				<div className={style.mobileWrapper}>
					<Link
						to="/research"
						className={
							window.location.href.endsWith("research")
								? activeNavItemClass
								: style.item
						}
						onClick={() => this.toggleActiveSideNavItem("")}
					>
						<img
							className={style.icon}
							src={people}
							alt="people icon"
						/>
						<p className={style.link}>People</p>
					</Link>
					<Link
						to="/research/finance"
						className={
							window.location.href.includes("finance")
								? activeNavItemClass
								: style.item
						}
						onClick={() => this.toggleActiveSideNavItem("finance")}
					>
						<img
							className={style.icon}
							src={economic}
							alt="economic icon"
						/>
						<p className={style.link}>Finance</p>
					</Link>
					<Link
						to="/research/toxicity"
						className={
							window.location.href.includes("toxicity")
								? activeNavItemClass
								: style.item
						}
						onClick={() => this.toggleActiveSideNavItem("toxicity")}
					>
						<img
							className={style.icon}
							src={toxic}
							alt="toxic icon"
						/>
						<p className={style.link}>Toxicity</p>
					</Link>
					<Link
						to="/research/nature"
						className={
							window.location.href.includes("nature")
								? activeNavItemClass
								: style.item
						}
						onClick={() => {
							this.toggleActiveSideNavItem("nature");
							window.innerWidth < 900 && this.toggleSubNav();
						}}>
						<img
							className={style.icon}
							src={window.innerWidth < 900 ? natureMobile : nature}
							alt="nature icon"
						/>
						<p className={style.link}>Nature</p>
					</Link>

					{/*_____________SUB NAV START_____________*/}
					<div className={subNav ? subNavActive : style.subNav}>
						{window.innerWidth < 900 && <Link
							to="/research/nature"
							className={
								window.location.href.includes("nature")
									? activeNavItemClass
									: style.item
							}
							onClick={() => this.toggleActiveSideNavItem("nature")}
						>
							<img
								className={style.icon}
								src={nature}
								alt="nature icon"
							/>
							<p className={style.link}>Nature</p>
						</Link>}

						<Link
							to="/research/trees"
							className={
								window.location.href.includes("trees")
									? activeNavItemClass
									: style.subitem
							}
							onClick={() => this.toggleActiveSideNavItem("trees")}
						>
							<img
								className={style.icon}
								src={trees}
								alt="nature icon"
							/>
							<p className={style.link}>Trees</p>
						</Link>
						<Link
							to="/research/water"
							className={
								window.location.href.includes("water")
									? activeNavItemClass
									: style.subitem
							}
							onClick={() => this.toggleActiveSideNavItem("water")}
						>
							<img
								className={style.icon}
								src={water}
								alt="water drop icon"
							/>
							<p className={style.link}>Water</p>
						</Link>
						<Link
							to="/research/co2"
							className={
								window.location.href.includes("co2")
									? activeNavItemClass
									: style.subitem
							}
							onClick={() => this.toggleActiveSideNavItem("co2")}
						>
							<img className={style.icon} src={co2} alt="co2 icon" />
							<p className={style.link}>CO2</p>
						</Link>

						{window.innerWidth < 900 &&
						<button
							onClick={this.toggleSubNav}
							className={style.subNavClose}>
							<IoIosClose/>
						</button>}
					</div>
					{/*_____________SUB NAV END_____________*/}
					<Link
						to="/research/sources"
						className={
							window.location.href.includes("sources")
								? activeNavItemClass
								: style.item
						}
						onClick={() => this.toggleActiveSideNavItem("sources")}
					>
						<img
							className={style.icon}
							src={sources}
							alt="sources"
						/>
						<p className={style.link}>Sources</p>
					</Link>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default withRouter(connect(mapStateToProps, actions)(SideNav)) ;
