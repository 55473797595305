import * as types from './types';

// Change Lang
export function nextPage(urlName){
    return {
        type: types.RESEARCH_NEXT_PAGE,
        urlName
    }
}

export function setScrollHeight(height){
    return {
        type: types.RESEARCH_SET_SCROLL_HEIGHT,
        height
    }
}