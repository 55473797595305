import React, { Component } from "react";
import style from "./index.module.scss";
import zeiptreceipt from "../../../assets/research/zeiptreceipt.svg";
import thermalprinter from "../../../assets/research/thermalprinter.svg";
import employee from "../../../assets/research/employee.svg";
import store from "../../../assets/research/store.svg";
import grocery from "../../../assets/research/grocery.svg";
import chain from "../../../assets/research/chain.svg";
import StorePrinting from "./StorePrinting/StorePrinting";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions/research";
import Accordion, { Accordions, Small } from "../Accordion";

class Finance extends Component {
	state = {
		activeArrow: [
			{ id: 1, active: false },
			{ id: 2, active: false },
			{ id: 3, active: false },
			{ id: 4, active: false },
			{ id: 5, active: false }
		],
		storePrinting: [
			{
				id: 0,
				img: store,
				title: "A hardware store printing",
				receipts: "100",
				paperReceipts: "5 840",
				digitalReceipts: "1 095"
			},
			{
				id: 1,
				img: grocery,
				title: "An electronics store printing",
				receipts: "1 000",
				paperReceipts: "58 400",
				digitalReceipts: "10 950"
			},
			{
				id: 2,
				img: chain,
				title: "A grocery store printing",
				receipts: "10 000",
				paperReceipts: "0,58M",
				digitalReceipts: "0,11M"
			}
		],
		activeStorePrinting: 0,
		storePrintingActiveArrow: [{ active: false }, { active: false }]
	};

	toggleActiveArrow = i => {
		const activeArrowArray = [...this.state.activeArrow];
		activeArrowArray[i].active = !activeArrowArray[i].active;
		this.setState({ activeArrow: activeArrowArray });
	};

	toggleStorePrintingActiveArrow = i => {
		const storePrintingActiveArrow = [
			...this.state.storePrintingActiveArrow
		];
		storePrintingActiveArrow[i].active = !storePrintingActiveArrow[i]
			.active;
		this.setState({ storePrintingActiveArrow });
	};

	storePrintingMore = () => {
		if (this.state.activeStorePrinting === 2) {
			return;
		} else {
			const currentStorePrinting = this.state.activeStorePrinting + 1;
			this.setState({ activeStorePrinting: currentStorePrinting });
		}
	};

	storePrintingLess = () => {
		if (this.state.activeStorePrinting === 0) {
			return;
		} else {
			const currentStorePrinting = this.state.activeStorePrinting - 1;
			this.setState({ activeStorePrinting: currentStorePrinting });
		}
	};

	goToNextPage = () => {
		this.props.nextPage("toxicity");
		window.scrollTo(0, this.props.research.height);
	};

	render() {
		//__Dest
		const { storePrinting, activeStorePrinting } = this.state;

		/* //__Combined classes
		const activeArrowClass = [style.icon, style.activeIcon].join(" ");
		const activeCostsClass = [style.costs, style.activeCosts].join(" "); */

		const storePrintingDom = storePrinting.map((item, i) => {
			return (
				<StorePrinting
					key={i}
					activeStorePrinting={this.state.activeStorePrinting}
					activeArrow={this.state.storePrintingActiveArrow}
					toggleActiveArrow={this.toggleStorePrintingActiveArrow}
					more={this.storePrintingMore}
					less={this.storePrintingLess}
					store={item}
				/>
			);
		});

		return (
			<div id="economic" className={style.economic}>
				<h3>Financial impact</h3>
				<p className={style.desc}>
					Paper receipts impact your business' finances and employees
					frustration.
				</p>
				<Accordions>
					<Accordion
						design={"big"}
						title={
							<>
								0,16 <i>NOK</i>
							</>
						}
						description={
							"Average cost of printing one paper receipt"
						}
					>
						<h6>One paper receipt</h6>
						<b>0,10 NOK</b>
						<h6>Thermal printer cost per paper receipt</h6>
						<b>0,06 NOK</b>

						<h6>Total</h6>
						<Small>(0,10 + 0,06)</Small>
						<b>= 0,16 NOK</b>
					</Accordion>
					<Accordion
						design={"big"}
						title={
							<>
								0,56 <i>NOK</i>
							</>
						}
						description={
							"Average cost of handling one paper receipt including employee cost"
						}
						explainer={
							"* Not including secondary time costs and frustrations such as return handling"
						}
					>
						<h6>Printing one paper receipt</h6>
						<b>0,16 NOK</b>

						<h6>Employee cost per paper receipt</h6>
						<b>0,40 NOK</b>

						<h6>Total</h6>
						<Small>(0,16 + 0,40)</Small>
						<b>= 0,56 NOK</b>
					</Accordion>
				</Accordions>
				<Accordions>
					<Accordion
						design={"small"}
						title={"0,10 NOK"}
						description={"One paper receipt"}
						image={zeiptreceipt}
					>
						<h6>Receipt roll</h6>
						<b>25 NOK</b>
						<h6>Length of receipt roll</h6>
						<b>55 m</b>
						<h6>Length of receipt</h6>
						<b>22,5 cm</b>
						<h6>Receipts in one roll</h6>
						<Small>(55m/22,5cm)</Small>
						<b>= 244 receipts</b>
						<h6>One paper receipt</h6>
						<Small>(25 NOK / 244 receipts)</Small>
						<b>= 0,10 NOK</b>
					</Accordion>
					<Accordion
						design={"small"}
						title={"0,06 NOK"}
						description={"Thermal printer"}
						image={thermalprinter}
					>
						<h6>Thermal printer</h6>
						<b>2 000 NOK</b>

						<h6>Yearly maintanance cost</h6>
						<b>700 NOK</b>

						<h6>Lifespan of thermal printer</h6>
						<b>7 years</b>

						<h6>Lifespan cost</h6>
						<Small>(2000 + (700 * 7))</Small>
						<b>= 6 900 NOK</b>

						<h6>Receipts per store per month</h6>
						<b>1 375 receipts</b>

						<h6>Receipts during printers lifespan</h6>
						<Small>(7 years * 12 months * 1375)</Small>
						<b>= 115 500 receipts</b>

						<h6>Printer cost for each receipt</h6>
						<Small>(6900 NOK / 115 500 receipts)</Small>
						<b>= 0,06 NOK</b>
					</Accordion>
					<Accordion
						design={"small"}
						title={"0,40 NOK"}
						description={"Employee"}
						image={employee}
					>
						<h6>Time to print receipt</h6>
						<b>2 seconds</b>

						<h6>Time to hand over receipt</h6>
						<b>3 seconds</b>

						<h6>"Would you like the receipt?"</h6>
						<b>2 seconds</b>

						<h6>Hourly wage</h6>
						<b>208 NOK/h</b>

						<h6>Employee cost</h6>
						<Small>(208 NOK/h * 7 seconds)</Small>
						<b>= 0,40 NOK</b>
					</Accordion>
				</Accordions>

				{/*__NUMBERS__*/}

				<div className={style.economicContent}>
					{/*__IMPACT__*/}
					<div className={style.impact}>
						<strong>
							How exactly do paper receipts impact companies
							economically?
						</strong>
						<p>
							Aside from the environmental and health related
							impact by paper receipts, there’s also an economical
							side to the equation. And as with many other things,
							the devil is in the details.
						</p>

						<p>
							While the actual cost of paper receipts may vary
							from one store to another, depending on factors such
							as...
						</p>
						<div style={{ marginTop: -20 }}>
							<li>
								how many transactions that are being processed
								per day
							</li>
							<li>how long the average receipt is</li>
							<li>cost of thermal paper in your market</li>
							<li>lifetime cost of the thermal printer</li>
							<li>
								time and cost for employees to handle receipts
							</li>
						</div>
						<p>
							... there's a general consensus that handling paper
							receipts is an unnecessary expense both in resources
							and frustration.
						</p>

						<strong>
							Here are some examples to illustrate costs for
							different types of stores:
						</strong>
					</div>
					{storePrintingDom[activeStorePrinting]}
				</div>

				<Link
					className={style.nextPage}
					to="/research/toxicity"
					onClick={this.goToNextPage}
				>
					<span className={style.link}>Toxic effects</span>
				</Link>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(Finance);
