import React from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Lottie from "react-lottie";
import animationData from "../../../assets/paymentlottie/data.js";

//__Lottie options
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid"
    }
};

const Goal = props => {
    //__Dest
    const { activeLang, goal } = props.lang;

    return (
        <section className={style.goal}>
            <div className={style.contentWrapper}>
                <div className={style.lottie}>
                    <Lottie
                        options={defaultOptions}
                        style={{
                            transform: "scale(1.2)",
                            pointerEvents: "none"
                        }}
                    />
                </div>
                <div className={style.content}>
                    <h2 className={style.paragraph}>{goal.p1[activeLang]}</h2>
                    <h5 className={style.paragraph}>
                        {ReactHtmlParser(goal.p2[activeLang])}
                    </h5>
                    <h5 className={style.paragraph}>
                        That way, we eliminate the purpose for printing the
                        paper receipt.
                    </h5>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(Goal);
