import React, { Component } from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actions from "../../../actions/research";

class Intro extends Component {
	introRef = React.createRef();

	componentDidMount() {
		this.resizeHandler();
		window.addEventListener("resize", this.resizeHandler);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeHandler);
	}

	resizeHandler = () => {
		this.props.setScrollHeight(this.introRef.current.clientHeight);
	};

	render() {
		return (
			<section ref={this.introRef} className={style.intro}>
				<div className={style.content}>
					<h1>The Research</h1>
					<h4>
						We have asked customers and employees in over a thousand
						stores about their experiences with receipts, and
						searched the web for countless hours.
						<br />
						<br />
						This is what we have found.
					</h4>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(Intro);
