// TOPICS
export const TOPICS_TOGGLE_ACTIVE = 'TOPICS_TOGGLE_ACTIVE';
export const TOPICS_CLOSE_ACTIVE = 'TOPICS_CLOSE_ACTIVE';

// POSTS
export const FETCHING_POSTS = 'FETCHING_POSTS';
export const POSTS_TOGGLE_ACTIVE = 'POSTS_TOGGLE_ACTIVE';
export const POSTS_CLOSE_ACTIVE = 'POSTS_CLOSE_ACTIVE';
export const FETCH_POSTS = 'FETCH_POSTS';
export const INCREMENT_CURRENT_POSTS = 'INCREMENT_CURRENT_POSTS';
export const DECREMENT_CURRENT_POSTS = 'DECREMENT_CURRENT_POSTS';
export const GO_TO_CURRENT_POSTS = 'GO_TO_CURRENT_POSTS';
export const TOGGLE_ACTIVE_FILTERS = 'TOGGLE_ACTIVE_FILTERS';
export const CLEAR_ACTIVE_FILTERS = 'CLEAR_ACTIVE_FILTERS';
export const REMOVE_CLOSEST_POST = 'REMOVE_CLOSEST_POST';

// LANG
export const CHANGE_LANG = 'CHANGE_LANG';

// COUNT
export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';

// NAV
export const NAV_ON_SCROLL = 'NAV_ON_SCROLL';
export const NAV_TOGGLE_MOBILE = 'NAV_TOGGLE_MOBILE';

// RESEARCH
export const RESEARCH_NEXT_PAGE = "RESEARCH_NEXT_PAGE";
export const RESEARCH_SET_SCROLL_HEIGHT = "RESEARCH_SET_SCROLL_HEIGHT";

// CONTRIBUTORS
export const FETCH_CONTRIBUTORS = "FETCH_CONTRIBUTORS";
export const FETCHING_CONTRIBUTORS = "FETCHING_CONTRIBUTORS";