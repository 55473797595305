import * as types from './types';
import sanityClient from '@sanity/client';
import {CLEAR_ACTIVE_FILTERS} from "./types";

// Init client
const client = sanityClient({
  projectId: "cvlwdgzk",
  dataset: "news",
  useCdn: true
});
// Query for client
const newsQuery = `*[_type == "post"] {
  _id,
  tags,
  title,
  mainImage,
  publishedAt,
  url,
  body,
  tekst
}`;

// Fetch all posts from api
export function fetchPosts ()  {
    return async function(dispatch){
        // SET LOADING
        dispatch({type: types.FETCHING_POSTS});
        try{
            const posts = await client
            .fetch(newsQuery)
            .then(res => {
                return res
            })
            dispatch({
                type: types.FETCH_POSTS,
                posts
            })
        } catch(e){
            console.log(e.response);
        }
    }
}

// Toggle active post label
 export function toggleActiveLabel (id) {
    return {
        type: types.POSTS_TOGGLE_ACTIVE,
        id
    }       
}

// Clear active filters
export function clearActiveFilters() {return{type: CLEAR_ACTIVE_FILTERS}}

// Close active label
export function closeActiveLabel(){return {type: types.POSTS_CLOSE_ACTIVE}}

// Increment current posts
export function incCurrentPosts(){
    return {type: types.INCREMENT_CURRENT_POSTS}
}
// Decrement current posts
export function decCurrentPosts(){
    return {type: types.DECREMENT_CURRENT_POSTS}
}

// Go to clicked bullet posts
export function goToCurrentPosts(index){
    return{
        type: types.GO_TO_CURRENT_POSTS,
        index
    }
}

export function toggleActiveFilters(filter){
    return{
        type: types.TOGGLE_ACTIVE_FILTERS,
        filter
    }
}

export function removeClosestPost(){
    return{type: types.REMOVE_CLOSEST_POST}
}