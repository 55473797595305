import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import style from "./index.module.scss";
import FocusTrap from "focus-trap-react";
import { IoIosClose } from "react-icons/io";
import * as actions from "../../../../actions/posts";

class postModal extends Component {
	componentDidMount() {
		document.querySelector("html").classList.add("scroll-lock");
	}

	componentWillUnmount() {
		document.querySelector("html").classList.remove("scroll-lock");
	}

	onKeyDown = e => {
		if (e.keyCode === 27) {
			this.props.closeActiveLabel();
		}
	};

	onClickOutside = event => {
		if (this.postModal && this.postModal.contains(event.target)) return;
		this.props.closeActiveLabel();
	};

	render() {
		const { activeLang, dates } = this.props.lang;
		const { allPosts, singlePost } = this.props.posts;

		const singlePostDom = allPosts && singlePost && allPosts[singlePost];

		const insidePost = singlePost => {
			// Dest
			const { title, publishedAt, body, mainImage, url } = singlePost;

			const newsDay = new Date(publishedAt).getDay();
			const newsMonth = new Date(publishedAt).getMonth();

			const newsDateDom = `
            ${dates.day[activeLang][newsDay]} 
            ${new Date(publishedAt).getDate()} 
            ${dates.month[activeLang][newsMonth]} 
            ${new Date(publishedAt).getFullYear()}`;

			return (
				<Fragment>
					<div className={style.imgWrapper}>
						<img
							alt={title[activeLang]}
							className={style.img}
							src={
								"https://cdn.sanity.io/images/cvlwdgzk/news/" +
								mainImage.asset._ref.split("-")[1] +
								"-" +
								mainImage.asset._ref.split("-")[2] +
								"." +
								mainImage.asset._ref.split("-")[3] +
								"?h=400"
							}
						/>
					</div>
					<div className={style.content}>
						<h3 className={style.heading}>{title[activeLang]}</h3>
						<b className={style.date}>{newsDateDom}</b>
						<p className={style.desc}>{body[activeLang]}</p>
						{url ? (
							<button className={style.btn}>
								<a
									href={url}
									rel="noopener noreferrer"
									target="_blank"
								>
									Read More
								</a>
							</button>
						) : null}
						<button
							onClick={this.props.closeActiveLabel}
							className={style.mobileCloseBtn}
						>
							Close
						</button>
					</div>
				</Fragment>
			);
		};

		return ReactDOM.createPortal(
			<FocusTrap>
				<aside
					className={style.postModalOverlay}
					onClick={e => this.onClickOutside(e)}
					onKeyDown={e => this.onKeyDown(e)}
				>
					<button
						type="button"
						onClick={this.props.closeActiveLabel}
						className={style.closeBtn}
					>
						<IoIosClose />
					</button>
					<div
						className={style.postModalContent}
						ref={n => (this.postModal = n)}
					>
						{this.props.posts.allPosts && insidePost(singlePostDom)}
					</div>
				</aside>
			</FocusTrap>,
			document.body
		);
	}
}

const mapStateToProps = state => {
	return {
		posts: state.posts,
		lang: state.lang
	};
};

export default connect(mapStateToProps, actions)(postModal);
