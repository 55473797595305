import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actionsLang from "../../actions/lang";
import * as actionsNav from "../../actions/nav";

import icon from "../../assets/logo.svg";
// import en from '../../assets/en.svg'
// import no from '../../assets/no.svg'

const actions = { ...actionsLang, ...actionsNav };

class Nav extends Component {
  render() {
    // Dest
    const { activeLang, nav } = this.props.lang;
    const { mobileView } = this.props.nav;
    const { toggleMobileMenu } = this.props;

    //__Active Classes
    const activeNavClass = [style.navItem, style.active].join(" ");

    const mobileLine1Active = [style.logoLine1, style.logoLine1Active].join(
      " "
    );
    const mobileLine2Active = [style.logoLine2, style.logoLine2Active].join(
      " "
    );
    const mobileLine3Active = [style.logoLine3, style.logoLine3Active].join(
      " "
    );
    const mobileContentView = [style.content, style.mobileContent].join(" ");

    return (
      <div
        className={
          this.props.nav.scrolled
            ? [style.nav, style.navScrolled].join(" ")
            : style.nav
        }
      >
        <NavLink onClick={mobileView ? toggleMobileMenu : null} to="/">
          <img
            className={style.mobileZeiptIcon}
            src={icon}
            alt="The Zeipt Project logo"
          />
        </NavLink>
        <div className={style.mobileLogo} onClick={toggleMobileMenu}>
          <div
            className={mobileView ? mobileLine1Active : style.logoLine1}
          ></div>
          <div
            className={mobileView ? mobileLine2Active : style.logoLine2}
          ></div>
          <div
            className={mobileView ? mobileLine3Active : style.logoLine3}
          ></div>
        </div>
        <div className={mobileView ? mobileContentView : style.content}>
          <NavLink to="/">
            <img
              className={style.icon}
              src={icon}
              alt="The Zeipt Project logo"
            />
          </NavLink>
          <div className={style.navigationWrapper}>
            <ul className={style.navigation}>
              <NavLink
                onClick={toggleMobileMenu}
                className={style.navItem}
                activeClassName={activeNavClass}
                to="/research"
              >
                {nav.research[activeLang]}
              </NavLink>
              <NavLink
                onClick={toggleMobileMenu}
                className={style.navItem}
                activeClassName={activeNavClass}
                to="/contributors"
              >
                {nav.contributors[activeLang]}
              </NavLink>
              <NavLink
                onClick={toggleMobileMenu}
                className={style.navItem}
                activeClassName={activeNavClass}
                to="/news"
              >
                {nav.news[activeLang]}
              </NavLink>
              <a
                onClick={toggleMobileMenu}
                className={style.navItem}
                href="https://www.zeipt.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {nav.forDevelopers[activeLang]}
              </a>
            </ul>
            {/* <div className={style.langWrapper}>
                            <img 
                                onClick={() => this.props.changeLang('en')}
                                className={activeLang === 'en' ? activeLangClass : style.lang} 
                                src={en} 
                                alt='english language'/>
                            <img
                                onClick={() => this.props.changeLang('no')} 
                                className={activeLang === 'no' ? activeLangClass : style.lang} 
                                src={no} 
                                alt='norwegian language' />
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    nav: state.nav,
  };
};

export default connect(mapStateToProps, actions)(Nav);
