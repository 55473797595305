import React from "react";
import style from "./index.module.scss";

import { TiMinus } from "react-icons/ti";
import { TiPlus } from "react-icons/ti";
import Accordion, { Accordions } from "../../Accordion";

const StorePrinting = props => {
	//__Combined classes
	const inactiveBtnClass = [style.btn, style.inactiveBtn].join(" ");

	return (
		<div className={style.storePrinting}>
			<div className={style.navigation}>
				<div className={style.heading}>
					<div
						className={
							props.activeStorePrinting === 0
								? inactiveBtnClass
								: style.btn
						}
						onClick={props.less}
					>
						<TiMinus />
					</div>
					<div className={style.imgWrapper}>
						<img src={props.store.img} alt="store icon" />
					</div>
					<p className={style.receiptsAmount}>
						{props.store.title}
						<br />
						<b>{props.store.receipts} receipts per day</b>.
					</p>
					<div
						className={
							props.activeStorePrinting === 2
								? inactiveBtnClass
								: style.btn
						}
						onClick={props.more}
					>
						<TiPlus />
					</div>
				</div>
			</div>
			<Accordions>
				<Accordion
					design={"big"}
					subtitle={"Paper receipts"}
					title={props.store.paperReceipts}
					description={"NOK/year"}
					explainer={"* Paper & printer cost alone"}
				></Accordion>
				<Accordion
					design={"big"}
					subtitle={<i>Digital receipts</i>}
					title={props.store.digitalReceipts}
					description={"NOK/year"}
				></Accordion>
			</Accordions>

			{/* <div className={style.amounts}>
				<div
					className={[style.amount, style.paperReceipts].join(" ")}
					onClick={() => props.toggleActiveArrow(0)}
				>
					<p className={style.name}>Paper receipts</p>
					<h2 className={style.numbers}>
						{props.store.paperReceipts}
					</h2>
					<div className={style.desc}>
						<p className={style.nok}>NOK/year</p>
						<div
							className={
								props.activeArrow[0].active
									? activeArrowClass
									: style.icon
							}
						>
							<FaAngleDown />
						</div>
						<div
							className={
								props.activeArrow[0].active
									? activeCostsClass
									: style.costs
							}
						>
							Vestibulizzle fizzle the bizzle sizzle izzle crazy
							orci luctizzle cool break it down posuere pimpin'
							Curae; Get down get down vitae nulla quizzle brizzle
							ornare aliquizzle. Phasellus yo erizzle. Fo shizzle
							volutpizzle accumsan velit. Praesent da bomb
							boofron, adipiscing vitae, gravida yo, interdizzle
							vitae, bizzle. Shut the shizzle up malesuada
							bibendum things. I'm in the shizzle izzle elizzle et
							augue porta i saw beyonces tizzles and my pizzle
							went crizzle. Nizzle you son of a bizzle augue. Dope
							sagittizzle. Go to hizzle eu lacizzle quizzle lacus
							phat own yo'. Donizzle dizzle tellizzle a felizzle
							fizzle mollizzle. Integer bling bling. Nam
							scelerisque. Quisque magna eros, dignissizzle vitae,
							porttitizzle ac, nizzle break yo neck, yall, orci.
							Yo commodo fo shizzle lectizzle.
						</div>
					</div>
				</div>
				<div
					className={[style.amount, style.digitalReceipts].join(" ")}
					onClick={() => props.toggleActiveArrow(1)}
				>
					<p className={style.name}>Digital receipts</p>
					<h2 className={style.numbers}>
						{props.store.digitalReceipts}
					</h2>
					<div className={style.desc}>
						<p className={style.nok}>NOK/year</p>
						<div
							className={
								props.activeArrow[1].active
									? activeArrowClass
									: style.icon
							}
						>
							<FaAngleDown />
						</div>
						<div
							className={
								props.activeArrow[1].active
									? activeCostsClass
									: style.costs
							}
						>
							Vestibulizzle fizzle the bizzle sizzle izzle crazy
							orci luctizzle cool break it down posuere pimpin'
							Curae; Get down get down vitae nulla quizzle brizzle
							ornare aliquizzle. Phasellus yo erizzle. Fo shizzle
							volutpizzle accumsan velit. Praesent da bomb
							boofron, adipiscing vitae, gravida yo, interdizzle
							vitae, bizzle. Shut the shizzle up malesuada
							bibendum things.
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default StorePrinting;
