import React, { Component } from "react";
import { connect } from "react-redux";
import style from "./index.module.scss";
import SingleNews from "./SingleNews/SingleNews";
import * as actionsPosts from "../../actions/posts";
import * as actionsNav from "../../actions/nav";

// Icons
import { FaFontAwesomeFlag } from "react-icons/fa";
import { FaCalendarDay } from "react-icons/fa";
import { FaNewspaper } from "react-icons/fa";
import { FaMedium } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { IoIosRefresh } from "react-icons/io";

import Loader from "../common/Loader/Loader";

const iconsArray = [
  <FaFontAwesomeFlag />,
  <FaCalendarDay />,
  <FaNewspaper />,
  <FaMedium />,
  <FaHandshake />,
];

// Multiple actions for redux
const actions = { ...actionsPosts, ...actionsNav };

class News extends Component {
  state = {
    filters: [
      { filterName: { en: "milestone", no: "milepæl" }, active: false },
      { filterName: { en: "event", no: "begivenhet" }, active: false },
      { filterName: { en: "news", no: "nyheter" }, active: false },
      { filterName: { en: "blog post", no: "blogg innlegg" }, active: false },
      { filterName: { en: "partnership", no: "samarbeid" }, active: false },
    ],
  };

  componentDidMount() {
    this.props.navOnScroll(true);
    window.scrollTo(0, 0);
  }

  toggleActiveFiltersClass = (i) => {
    const filters = this.state.filters.map((filter) => {
      if (this.state.filters.indexOf(filter) === i) {
        return { ...filter, active: true };
      } else {
        return { ...filter, active: false };
      }
    });
    this.setState({ filters });
  };

  render() {
    // Dest
    const { filteredPosts, allPosts } = this.props.posts;
    const { activeLang } = this.props.lang;

    // Filter posts by selected filter
    const posts =
      filteredPosts &&
      filteredPosts.map((post) => {
        if (!post.mainImage) {
          return null;
        } else {
          return (
            <SingleNews
              key={post._id}
              title={post.title[activeLang]}
              publishedAt={post.publishedAt}
              body={post.body[activeLang]}
              mainImage={post.mainImage}
              url={post.url}
            />
          );
        }
      });

    //__Active classes
    const activeFilterClass = [style.filter, style.active].join(" ");

    //__Count
    const count = allPosts && filteredPosts.length + " / " + allPosts.length;

    const filters = this.state.filters.map((filter, i) => {
      return (
        <div
          onClick={() => {
            this.props.toggleActiveFilters(filter.filterName.en);
            this.toggleActiveFiltersClass(i);
          }}
          key={i}
          className={filter.active ? activeFilterClass : style.filter}
        >
          {iconsArray[i]}
          <p className={style.filterText}>{filter.filterName[activeLang]}</p>
        </div>
      );
    });

    // Display Loader
    if (!allPosts) {
      return <Loader />;
    }

    return (
      <section className={style.news}>
        <div className={style.filters}>{filters}</div>
        <h3 className={style.count}>
          {count}
          <br />
          {this.props.posts.allPosts.length !==
            this.props.posts.filteredPosts.length && (
            <span
              onClick={() => {
                this.props.clearActiveFilters();
                this.toggleActiveFiltersClass();
              }}
              className={style.clearFilters}
            >
              <IoIosRefresh />
              clear
            </span>
          )}
        </h3>
        {posts.reverse()}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.posts,
    lang: state.lang,
  };
};

export default connect(mapStateToProps, actions)(News);
