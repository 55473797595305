import React from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";

const SingleNews = props => {
	// Dest
	const { title, publishedAt, body, mainImage, url } = props;
	const { activeLang, dates } = props.lang;

	const newsDay = new Date(publishedAt).getDay();
	const newsMonth = new Date(publishedAt).getMonth();

	const newsDateDom = `
        ${dates.day[activeLang][newsDay]} 
        ${new Date(publishedAt).getDate()} 
        ${dates.month[activeLang][newsMonth]} 
        ${new Date(publishedAt).getFullYear()}`;

	return (
		<div className={style.singleNews}>
			<div className={style.img}>
				<img
					alt={title}
					className={style.img}
					src={
						"https://cdn.sanity.io/images/cvlwdgzk/news/" +
						mainImage.asset._ref.split("-")[1] +
						"-" +
						mainImage.asset._ref.split("-")[2] +
						"." +
						mainImage.asset._ref.split("-")[3] +
						"?h=400"
					}
				/>
			</div>
			<p className={style.date}>{newsDateDom}</p>
			<h3 className={style.heading}>{title}</h3>
			<p className={style.desc}>{body}</p>
			{url ? (
				<button className={style.btn}>
					<a href={url} rel="noopener noreferrer" target="_blank">
						Read More
					</a>
				</button>
			) : null}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		lang: state.lang
	};
};

export default connect(mapStateToProps)(SingleNews);
