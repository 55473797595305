import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./sass/index.scss";
import { Provider } from "react-redux";
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import postsReducer from "./reducers/postsReducer";
import langReducer from "./reducers/langReducer";
import countReducer from "./reducers/countReducer";
import navReducer from "./reducers/navReducer";
import researchReducer from "./reducers/researchReducer";
import contributorsReducer from "./reducers/contributorsReducer";
import { ThemeProvider } from "styled-components";
const rootReducer = combineReducers({
	posts: postsReducer,
	lang: langReducer,
	count: countReducer,
	nav: navReducer,
	research: researchReducer,
	contributors: contributorsReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);
const theme = {
	black: "#0f2841",
	grey: "#6b6b6b",
	white: "white",
	whiteBack: "#e7e9ec",

	blue: "#00519a",
	blueBorder: "#b2cae0",
	blueBack: "#e5edf4",

	green: "#3cbf71",
	greenBorder: "#cdedd7",
	greenBack: "#eef8f2",

	pink: "#e52e6a",
	pinkBorder: "#f9c6d6",
	pinkBack: "#fdecf1",

	yellow: "#ffc100",
	yellowBorder: "#ffecb2",
	yellowBack: "#fff8e5",
	yellowDark: "#FFC100"
};

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</Provider>,
	document.getElementById("root")
);
