import React, { Component, Fragment } from "react";
import style from "./index.module.scss";
import Header from "./Header/Header";
import WeWaste from "./WeWaste/WeWaste";
import Loader from "../common/Loader/Loader";
import Goal from "./Goal/Goal";
import Info from "./Info/Info";
import { connect } from "react-redux";
import * as actionsNav from "../../actions/nav";
import * as actionsPosts from "../../actions/posts";

const actions = { ...actionsNav, ...actionsPosts };

class LandingPage extends Component {
	componentDidMount() {
		//    Show nav if scrolled more than 100px
		this.navScrollHandler();
		window.addEventListener("scroll", this.navScrollHandler);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.navScrollHandler);
	}

	navScrollHandler = () => {
		if (window.scrollY > 10) {
			this.props.navOnScroll(true);
		} else {
			this.props.navOnScroll(false);
		}
	};

	render() {
		// Display loader
		if (this.props.posts.loading) {
			return <Loader />;
		}

		return (
			<section className={style.landingPage}>
				<Fragment>
					<Header />
					<WeWaste />
					<Goal />
					<Info />
				</Fragment>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		posts: state.posts
	};
};

export default connect(mapStateToProps, actions)(LandingPage);
