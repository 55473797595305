import * as types from '../actions/types';

const initialState = {
    show: false,
    mobileView: false,
    prevScroll: window.pageYOffset,
    scrolled: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NAV_ON_SCROLL:
            return {...state, scrolled: action.show};
        case types.NAV_TOGGLE_MOBILE:
            return {...state, mobileView: !state.mobileView};

        default:
            return state;
    }
};