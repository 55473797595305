import React, { Component } from "react";
import style from "./index.module.scss";
import cancer from "../../../assets/research/cancer.svg";
import heart from "../../../assets/research/heart.svg";
import diabetes from "../../../assets/research/diabetes.svg";
import ashtma from "../../../assets/research/ashtma.svg";
import time from "../../../assets/research/time.svg";
import receiptemployee from "../../../assets/research/receiptemployee.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../actions/research";
import Accordion, { Accordions, Small } from "../Accordion";

class Toxicity extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  goToNextPage = () => {
    this.props.nextPage("nature");
    window.scrollTo(0, this.props.research.height);
  };

  render() {
    return (
      <div id="toxicity" className={style.toxicity}>
        <h3 className={style.heading}>An increase in BPA exposure</h3>
        <p className={style.desc}>
          The production and usage of paper receipts have a direct, negative
          imapct on yourself, and the planet.
        </p>

        <p>
          This chapter focuses on the "hormon disrupting substance" contained in
          receipts, named BPA (Bishphenol A). The calculation that follows
          illustrates the Tolarable Daily Intake (TDI) before effects appear. A
          number above 1 × TDI is considered harmfull.
        </p>

        <strong>Think about your employees</strong>
        <Accordions>
          <Accordion
            design={"small"}
            image={receiptemployee}
            title={"6 receipts / day"}
            description={"One shop employee handles on average"}
          >
            <h6>Average amount of transactions in a store per month</h6>
            <b>1 375</b>

            <h6>Transactions per day</h6>
            <b>46</b>

            <h6>Average of employees in a store</h6>
            <b>3</b>

            <h6>Transactions per employee per day</h6>
            <b>15</b>

            <h6>Percentage of receipts printed out</h6>
            <b>40%</b>

            <h6>Receipts handled per employee</h6>
            <Small>(40% of 15)</Small>
            <b>6 receipts</b>
          </Accordion>
          <Accordion
            design={"small"}
            image={time}
            title={"18 seconds / day"}
            description={"Employee's average contact with receipts"}
          >
            <h6>6 receipts / day * 3 seconds to handle</h6>
            <b>18 seconds</b>
          </Accordion>
        </Accordions>

        <Accordions style={{ marginBottom: 40 }}>
          <form>
            <div className={style.inputGroup}>
              <input
                type="number"
                required
                value={this.state.value}
                onChange={this.handleChange}
              />
              <label>Insert your weight (kg)</label>
            </div>
          </form>

          <Accordion
            design={"small"}
            title={"4 µg / kg per day"}
            description={
              <>
                Max. Tolerable Daily Intake <b>(TDI)</b> set by EU
              </>
            }
            explainer={
              <>
                * Before harmful impact (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.efsa.europa.eu/en/topics/topic/bisphenol"
                >
                  source
                </a>
                )
              </>
            }
          ></Accordion>
        </Accordions>
        <Small style={{ marginBottom: 10 }}>
          Avg. daily dose of BPA from receipts per employee in a store:
        </Small>

        <Accordions>
          <Accordion
            design={"small"}
            title={
              this.state.value > 0
                ? (203 / this.state.value / 4).toFixed(2) + " × TDI"
                : " 0 × TDI"
            }
            explainer={"* Best case scenario"}
          >
            <h6>
              Conservatively calculated absorption of BPA in contact with paper
              receipts
            </h6>
            <Small>
              (
              <a
                href={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5453537/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                source
              </a>
              )
            </Small>
            <b>1,072 to 21,522 µg/sec</b>

            <h6>Average daily BPA absorbed for a shop employee</h6>
            <Small>((1,072 + 21,522) / 2) * 18 seconds</Small>
            <b>203 µg / day</b>

            <h6>Daily intake</h6>
            <Small>203 / {this.state.value} kg</Small>
            <b>{(203 / this.state.value).toFixed(2)} µg / kg per day</b>
          </Accordion>

          <Accordion
            design={"small"}
            title={
              this.state.value > 0
                ? (43150 / this.state.value / 4).toFixed(2) + " × TDI"
                : " 0 × TDI"
            }
            explainer={
              <>
                * Worst case scenario. (Including moist and/or hand sanitizer) (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://d36rd3gki5z3d3.cloudfront.net/wp-content/uploads/2019/02/The-hidden-cost-of-receipts-BPA-BPS-Feb-2019-1.pdf?x14820"
                >
                  source
                </a>
                )
              </>
            }
          >
            <h6>
              Absorption of BPA in contact with paper receipts with moist hands
            </h6>
            <Small>
              (
              <a
                href={"https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5453537/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                source
              </a>
              )
            </Small>
            <b>474,67 to 4 319,85 µg/sec</b>

            <h6>Average daily BPA absorbed for a shop employee</h6>
            <Small>((474,67 + 4 319,85) / 2) * 18 seconds</Small>
            <b>43 150 µg / day</b>

            <h6>Daily intake</h6>
            <Small>43 150 / {this.state.value} kg</Small>
            <b>{(43150 / this.state.value).toFixed(2)} µg / kg per day</b>
          </Accordion>
          <Accordion
            design={"small"}
            title={
              this.state.value > 0
                ? (4498 / this.state.value / 4).toFixed(2) + " × TDI"
                : " 0 × TDI"
            }
            explainer={
              "* Given worst case scenario happens 10% of times handling a receipt"
            }
          >
            <h6>BPA intake 90% of best case</h6>
            <Small>(90% * 203) + (10% * 43 150)</Small>
            <b>4 498 µg / day</b>

            <h6>Per kg</h6>
            <Small>4 498 / {this.state.value} kg</Small>
            <b>{(4498 / this.state.value).toFixed(2)} µg / kg per day</b>
          </Accordion>
        </Accordions>
        <p>
          For further understanding of the reasoning behind the Tolerable Daily
          Intake (TDI){" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.bisphenol-a-europe.org/wp-content/uploads/2017/04/How-much-BPA-does-a-typical-person-take-in-through-a-normal-diet.pdf"
          >
            this is the resource we used as reference
          </a>
          .
        </p>

        <p>
          Our calculation is also conservative in contrast to{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://environmentaldefence.ca/wp-content/uploads/2019/02/The-hidden-cost-of-receipts-BPA-BPS-Feb-2019-1.pdf"
          >
            this Canadian study
          </a>
          , where participants were given 17 minutes of total exposure to
          receipts. This study shows an increased absorption of chemicals in the
          body including (BPA/BPS) with an average increase from baseline
          measurement of 67.14 times... Among other findings this study also
          highlights the fact that sanitizer dramatically increases absorption
          rates. As this is widely used by cashiers, we are fairly confident
          that our findings are conservative in nature. Please have a look at
          their report and draw your own conclusion.
        </p>

        <strong className={style.paragraphHeading}>
          Why an increase in BPA exposure is a problem?
        </strong>
        <p className={style.paragraph}>
          For starters, the chemical BPA (Bishphenol A) is a well known
          endocrine disruptor... But what does this really mean? Well, in short,
          BPA has the ability to imitate the body's hormones, which in turn
          causes it to interfere with your body's natural hormone levels,
          causing an imbalance that impacts both men and women of all ages.
        </p>

        <p className={style.paragraph}>
          Furthermore, this imbalance is linked to a great variety of health
          effects, such as:
        </p>
        <ul className={style.list}>
          <li className={style.item}>
            <img src={cancer} alt="cancer icon" />
            <p className={style.itemDesc}>
              An increase in hormone-related cancers, such as breast and
              prostate cancer.
            </p>
          </li>
          <li className={style.item}>
            <img src={heart} alt="heart icon" />
            <p className={style.itemDesc}>
              Various types of heart diseases, like coronary artery heart
              disease, angina, heart attacks, hypertension and peripheral artery
              disease. Exposure to the chemical is also linked to arrhythmias,
              atherosclerosis and changes in your blood pressure.
            </p>
          </li>
          <li className={style.item}>
            <img src={diabetes} alt="diabetes icon" />
            <p className={style.itemDesc}>
              An increased chance of developing types 2 diabetes, due to BPA'a
              contribution to insulin resistance.
            </p>
          </li>
          <li className={style.item}>
            <img src={ashtma} alt="asthma icon" />
            <p className={style.itemDesc}>
              Increased risks of developing asthma, a lung condition.
            </p>
          </li>
        </ul>
        <p className={style.paragraph}>
          Now you might be wondering,{" "}
          <i>
            "how exactly do i get exposed to this chemical, and how is this
            related to paper receipts?"
          </i>
        </p>
        <p className={style.paragraph}>
          Well, the answer is strikingly simple: Paper receipts are the same as
          thermal paper. Thermal paper printers, such as those printing your
          paper receipts, doesn't contain ink cartridges like your regular
          household printer. Instead, the thermal paper used in these printers
          are coated with a layer of BPA, which changes color when heat is
          applied. This is in turn what causes black text to appear on a white
          paper receipt.
        </p>
        <p className={style.paragraph}>
          While the idea of inkless printing is quite an ingenious idea by
          itself, the execution of this process isn't quite as glamorous. For
          instance, when you touch a paper receipts, your body immediately
          starts absorbing the chemical into your bloodstream. This in turn
          causes your body to experience the effects of the BPA instantly, from
          the moment you first come into contact with a receipt. And of course,
          the more receipts you come into contact with and the longer you hold
          them, the higher your exposure will be, and the more likely you are to
          experience the health effects mentioned above.
        </p>
        <p>
          This is not only a concern in the scientific community. EU has for
          years regulated the use of this substance, and{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://chemicalwatch.com/77769/switzerland-bans-the-use-of-bpa-and-bps-in-thermal-paper"
          >
            Switzerland has just approved an even more conservative stand on its
            usage as measurement
          </a>{" "}
          to protect the general public.
        </p>
        <Link
          className={style.nextPage}
          to="/research/nature"
          onClick={this.goToNextPage}
        >
          <span className={style.link}>Impact on nature</span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    research: state.research,
  };
};

export default connect(mapStateToProps, actions)(Toxicity);
