import React from "react";
import style from "./index.module.scss";
import car from "../../../assets/research/car.svg";
import temp from "../../../assets/research/temp.svg";
import fire from "../../../assets/research/fire.svg";
import hurricane from "../../../assets/research/hurricane.svg";
import flood from "../../../assets/research/flood.svg";
import acid_rain from "../../../assets/research/acid_rain.svg";
import health from "../../../assets/research/health.svg";
import Accordion, { Accordions, Small } from "../Accordion";

const CO2 = () => {
	return (
		<div className={style.co2} id="co2">
			<h3 className={style.heading}>An increase in CO2 emissions</h3>
			<Accordions>
				<Accordion
					design={"big"}
					title={"3 808 000"}
					description={"cars per year"}
					image={car}
					subtitle={
						"Paper receipts costs our planet the equivalent of"
					}
				>
					<h6>
						Number of tons of thermal paper used for paper receipts
						each year
					</h6>
					<b>2,2 million tons</b>

					<h6>Emmisions per ton thermal paper produced</h6>
					<b>8,57 tons of CO2</b>

					<h6>Annual emmisions for producing paper receipts</h6>
					<Small>(2 200 000 * 8,57)</Small>
					<b>18,85 million tons of CO2</b>

					<h6>Emmision of typical passenger vehicle</h6>
					<b>4,95 tons of CO2 per year</b>

					<h6>Receipt emmisions per car</h6>
					<Small>(18 850 000 / 4,95)</Small>
					<b>3 808 000 cars</b>
				</Accordion>
			</Accordions>
			<strong className={style.paragraphHeading}>
				Why is an increase of CO2 a problem?
			</strong>
			<p className={style.paragraph}>
				In short, the increase of CO2 (Carbon Dioxide) contributes to a
				rise in heat in our atmosphere, which in turn causes for an
				accelerated change in our climate. To summarize, here are some
				of the most prominent changes we’re likely to see in the near
				future:
			</p>
			<ul className={style.list}>
				<li className={style.item}>
					<div className={style.icon}>
						<img src={temp} alt="termometer icon" />
					</div>
					<p className={style.itemText}>
						An increase in global temperatures
					</p>
				</li>
				<li className={style.item}>
					<div className={style.icon}>
						<img src={fire} alt="fire icon" />
					</div>
					<p className={style.itemText}>
						Wildfires become more frequent with the increase of
						draughts and heatwaves.
					</p>
				</li>
				<li className={style.item}>
					<div className={style.icon}>
						<img src={hurricane} alt="hurricane icon" />
					</div>
					<p className={style.itemText}>
						Deadly, and destructive hurricanes become more frequent
						and powerful.
					</p>
				</li>
				<li className={style.item}>
					<div className={style.icon}>
						<img src={flood} alt="flood icon" />
					</div>
					<p className={style.itemText}>
						Sea levels will continue to rise, making parts of the
						world inhabitable.
					</p>
				</li>
				<li className={style.item}>
					<div className={style.icon}>
						<img src={acid_rain} alt="acid rain icon" />
					</div>
					<p className={style.itemText}>
						An increase in acidic rain, damaging trees and other
						plant life.
					</p>
				</li>
				<li className={style.item}>
					<div className={style.icon}>
						<img src={health} alt="health icon" />
					</div>
					<p className={style.itemText}>
						An increase in health problems, as CO2 emissions are
						generally accompanied by an increase of harmful
						particles in the air.
					</p>
				</li>
			</ul>
			<strong className={style.paragraphHeading}>
				How does paper receipts increase CO2 emissions?
			</strong>
			<p className={style.paragraph}>
				The manufacturing process, shipment and disposal of thermal
				paper used for paper receipts causes a significant amount of CO2
				to be released into our atmosphere.
			</p>
			<p className={style.paragraph}>
				To produce a ton of thermal paper, the paper industry releases
				8,573 metric tonnes of CO2. For comparison, a typical passenger
				vehicle emits 4.95 metric tons (or 4.600 kilograms) of CO2 per
				year. And In 2018 alone, more than 3.1 million tons of thermal
				paper was sold globally, and the consumption is expected to grow
				by a rate of 2.7% in 2019.
			</p>
			<p className={style.paragraph}>
				So that, ladies and gentlemen, translates to 27.7 million metric
				tons of CO2 released into our atmosphere on a yearly basis due
				to thermal paper production! And a whopping 18.85 million metric
				tons are strictly related to “point of sale”, or paper receipts.
				Add factoring shipment and disposal into the equation, and the
				number becomes significantly higher.
			</p>
		</div>
	);
};

export default CO2;
