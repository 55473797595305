import React from "react";
import style from "./index.module.scss";

const Contributor = ({ logo, name, openModal }) => {
	return (
		<div className={style.contributor} onClick={openModal}>
			{logo ? (
				<img
					alt={name}
					className={style.img}
					src={
						"https://cdn.sanity.io/images/cvlwdgzk/news/" +
						logo.asset._ref.split("-")[1] +
						"-" +
						logo.asset._ref.split("-")[2] +
						"." +
						logo.asset._ref.split("-")[3] +
						"?h=80"
					}
				/>
			) : (
				<i>Coming soon</i>
			)}
		</div>
	);
};

export default Contributor;
