import React, { Component } from "react";
import style from "./index.module.scss";
import SideNav from "./SideNav/SideNav";
import People from "./People/People";
import Economic from "./Finance/Finance";
import Toxicity from "./Toxicity/Toxicity";
import Nature from "./Nature/Nature";
import Trees from "./Trees/Trees";
import Water from "./Water/Water";
import CO2 from "./CO2/CO2";
import Sources from "./Sources/Sources";
import { connect } from "react-redux";
import * as actions from "../../actions/nav";
import Intro from "./Intro/Intro";
import { Route, Switch } from "react-router-dom";
import SubError from "../common/Error/SubError";

class Research extends Component {
	componentDidMount() {
		this.props.navOnScroll(true);
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<section className={style.research}>
				<Intro />
				<div className={style.content}>
					<SideNav />
					<div className={style.contentInner}>
						<Switch>
							<Route path="/research" exact component={People} />
							<Route path="/research/finance" exact component={Economic} />
							<Route path="/research/toxicity" exact component={Toxicity} />
							<Route path="/research/nature" exact component={Nature} />
							<Route path="/research/trees" exact component={Trees} />
							<Route path="/research/water" exact component={Water} />
							<Route path="/research/co2" exact component={CO2} />
							<Route path="/research/sources" exact component={Sources} />
							<Route component={SubError}/>
						</Switch>
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		research: state.research
	};
};

export default connect(mapStateToProps, actions)(Research);
