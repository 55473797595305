import React, { Component } from "react";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import toxic from "../../../assets/info/icon_toxic.svg";
import bin from "../../../assets/info/bin.svg";
import percent from "../../../assets/info/80percent.svg";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";

class Info extends Component {
    state = {
        activeSlide: 0
    };

    incrementActiveSlide = () => {
        if (this.state.activeSlide >= 2) {
            return;
        } else {
            this.setState({ activeSlide: this.state.activeSlide + 1 });
        }
    };

    decrementActiveSlide = () => {
        if (this.state.activeSlide <= 0) {
            return;
        } else {
            this.setState({ activeSlide: this.state.activeSlide - 1 });
        }
    };

    render() {
        //__Dest
        const { activeLang, info } = this.props.lang;
        const { activeSlide } = this.state;

        //__Image array for mapping slides
        const imgArray = [toxic, percent, bin];

        //__Active Classes
        const inactiveArrow = [style.arrow, style.inactiveArrow].join(" ");

        //__Slides
        const slidesDom = imgArray.map((img, i) => {
            return (
                <div className={style.slide} key={i}>
                    <img className={style.icon} src={img} alt="icon" />
                    <h5 className={style.slideDesc}>
                        {info.slides[i][activeLang]}
                    </h5>
                </div>
            );
        });

        return (
            <section className={style.info}>
                <div className={style.contentWrapper}>
                    <h5 className={style.intro}>{info.intro[activeLang]}</h5>
                    <div className={style.slider}>
                        <div
                            className={
                                activeSlide === 0 ? inactiveArrow : style.arrow
                            }
                            onClick={this.decrementActiveSlide}
                        >
                            <FiChevronLeft />
                        </div>
                        {slidesDom[this.state.activeSlide]}
                        <div
                            className={
                                activeSlide === 2 ? inactiveArrow : style.arrow
                            }
                            onClick={this.incrementActiveSlide}
                        >
                            <FiChevronRight />
                        </div>
                    </div>
                    <Link to="research" className={style.btn}>
                        {info.btn[activeLang]}
                    </Link>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(Info);
