import React from "react";
import style from "./index.module.scss";
import { connect } from "react-redux";
import * as actions from "../../../../actions/posts";
import Clock from "./Clock";

const PostLabel = (props) =>  {

    //__Dest
    const { _id, title, publishedAt, tags } = props.post;
    const { activeLang, dates } = props.lang;
    const { toggleActiveLabel } = props;

    const todayEventClass = () => {
        // Check if there is an event today
        const today = new Date();
        const todayDate = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`;
        const postYear = new Date(publishedAt).getFullYear();
        const postMonth = new Date(publishedAt).getMonth() + 1;
        const postDay = new Date(publishedAt).getDate();
        const postFullDate = `${postYear}${postMonth}${postDay}`;
        return postFullDate - todayDate === 0;
    };

    const postDay = new Date(publishedAt).getDate();
    const postMonth = new Date(publishedAt).getMonth();

    const postDateDom = `${postDay}. ${dates.month[activeLang][postMonth]}`;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const bubbleDom = () => {
        if(title === 'today'){
            return (
                <div className={[style.bubble, style.today].join(' ')}>
                    <div className={style.label}>
                        <b className={style.labelDate}>
                            {publishedAt ? postDateDom : " "}
                        </b>
                        <p className={style.labelHeading}><Clock/></p>
                    </div>
                </div>
            )
        } else if (tags.includes('milestone')){
            return (
                <div
                    onClick={() => {
                        scrollToTop();
                        _id.length > 1 && toggleActiveLabel(_id);
                    }}
                    className={[style.bubble, style.mileStone].join(' ')}
                >
                    <div className={style.label}>
                        <b className={style.labelDate}>
                            {publishedAt ? postDateDom : " "}
                        </b>
                        <p className={style.labelHeading}>{title[activeLang]}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div
                    onClick={() => {
                        scrollToTop();
                        _id.length > 1 && toggleActiveLabel(_id);
                    }}
                    className={todayEventClass() ? [style.bubbleToday, style.bubbleToday].join(' ') : style.bubble}
                >
                    <div className={style.label}>
                        <b className={style.labelDate}>
                            {todayEventClass() ? 'TODAY' : postDateDom}
                        </b>
                        <p className={style.labelHeading}>{title[activeLang]}</p>
                    </div>
                </div>
            )
        }
    };

    return ( bubbleDom() );
};

const mapStateToProps = state => {
    return {
        posts: state.posts,
        lang: state.lang
    };
};

export default connect(mapStateToProps, actions)(PostLabel);
