import React, { Component } from "react";
import styled from "styled-components";

class Accordion extends Component {
	state = {
		open: false
	};

	onClick = () => {
		this.setState({
			open: !this.state.open
		});
	};

	render() {
		const { props, onClick } = this;
		return (
			<AccordionWrapper>
				<AccordionHeader
					onClick={onClick}
					clickable={props.children}
					open={this.state.open}
				>
					{props.design === "big" ? (
						<Bigger>
							{props.image ? (
								<img
									src={props.image}
									alt="zeiptreceipt icon"
									style={{
										maxHeight: 120,
										width: 100,
										marginRight: 40
									}}
								/>
							) : null}
							<div>
								<Subtitle>{props.subtitle}</Subtitle>

								<h2>
									{props.title}
									<span>?</span>
								</h2>
								<p>{props.description}</p>
							</div>
						</Bigger>
					) : props.design === "small" ? (
						<Smaller>
							{props.image ? (
								<img
									src={props.image}
									alt="zeiptreceipt icon"
								/>
							) : null}
							<div>
								<Small>{props.description}</Small>
								<h5>
									<b>{props.title}</b>
									<span>?</span>
								</h5>
							</div>
						</Smaller>
					) : null}
				</AccordionHeader>
				<Small>{props.explainer}</Small>
				<AccordionBody open={this.state.open}>
					{props.children}
				</AccordionBody>
			</AccordionWrapper>
		);
	}
}
export default Accordion;

const AccordionWrapper = styled.div`
	width: 100%;
	max-width: 600px;
	margin-right: 40px;
	p {
		margin-bottom: 0 !important;
	}
	@media (max-width: 900px) {
		margin-right: 0px;
		margin-bottom: 40px;
	}
`;

const AccordionHeader = styled.div`
	pointer-events: ${props => (props.clickable ? "auto" : "none")};
	cursor: pointer;

	border-bottom: 1px solid ${props => props.theme.blueBorder};
	padding-bottom: 10px;

	:hover {
		span {
			background-color: ${props => props.theme.pink};
			color: white;
		}
	}
	h5,
	h2 {
		color: ${props => props.theme.blue};
		position: relative;
		margin-bottom: 0 !important;
	}
	h2 i {
		font-size: 26px;
		font-style: unset;
	}
	span {
		display: ${props => (props.clickable ? "block" : "none")};
		transition: 0.2s;
		color: ${props => (props.open ? "white" : props.theme.pink)};
		position: absolute;
		right: 0;
		bottom: 5px;
		width: 20px;
		height: 20px;
		text-align: center;
		border: 1px solid ${props => props.theme.pink};
		line-height: 20px;
		border-radius: 100%;
		font-size: 14px;
		font-weight: 100;
		background-color: ${props => (props.open ? props.theme.pink : "white")};
	}
	img {
		width: 30px;
		max-height: 46px;
		margin-right: 10px;
	}
`;

const AccordionBody = styled.div`
	overflow: hidden;
	max-height: ${props => (props.open ? "1000px" : 0)};
	h6 {
		font-weight: bold;
		margin-top: 10px;
	}

	table {
		width: 100%;
		font-size: 12px;
		margin-top: 20px;
		border-spacing: 0 10px;

		tr {
			td:first-of-type {
				width: 120px;
			}
		}
	}
`;

const Bigger = styled.div`
	display: flex;
	@media (max-width: 900px) {
		display: block;
	}
	> div {
		width: 100%;
		position: relative;
	}
`;

const Smaller = styled.div`
	display: flex;
	> div {
		width: 100%;
		position: relative;
	}
`;
const Subtitle = styled.p`
	color: ${props => props.theme.pink};
	i {
		color: ${props => props.theme.green};
		font-style: normal !important;
	}
`;

export const Small = styled.p`
	font-size: 12px;
	a {
		font-size: 12px;
	}
`;

export const Accordions = styled.div`
	display: flex;
	@media (max-width: 900px) {
		display: block;
	}
	margin-bottom: 80px;
	p {
		margin: 0 !important;
	}
`;
