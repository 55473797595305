import * as types from "../actions/types";

const initialState = {
	activeSideNavItem: "people",
	height: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.RESEARCH_NEXT_PAGE: {
			return { ...state, activeSideNavItem: action.urlName };
		}
		case types.RESEARCH_SET_SCROLL_HEIGHT: {
			let scrollHeight;
			if (window.innerWidth <= 900) {
				scrollHeight = action.height - 60;
			} else {
				scrollHeight = action.height;
			}
			return { ...state, height: scrollHeight };
		}

		default:
			return state;
	}
};
