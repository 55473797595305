import React, {Component, Fragment} from 'react';

class Clock extends Component {
    state = {
        time: new Date().toLocaleTimeString()
    };

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    tick() {
        this.setState({
            time: new Date().toLocaleTimeString()
        });
    }

    render() {
        return (
            <Fragment>
                {this.state.time}
            </Fragment>
        );
    }
}

export default Clock;