import React, { Component } from "react";
import Chart from "react-apexcharts";

// Example URL
// https://apexcharts.com/react-chart-demos/bar-charts/grouped/

class BarChart extends Component {
	state = {
		dummySeries: [
			{
				name: "* (Collected points / 10)",
				data: [0, 0, 0, 0, 0]
			},
			{
				name: "Perfect",
				data: [0, 0, 0, 0, 0]
			}
		],
		series: [
			{
				name: "* (Collected points / 10)",
				data: [124.1, 112.7, 110.6, 110.3, 79.8]
			},
			{
				name: "Perfect",
				data: [107, 83, 78, 76, 39]
			}
		],
		options: {
			responsive: [
				{
					breakpoint: 900,
					options: {}
				}
			],

			legend: {
				position: "right"
			},
			colors: ["#00519A", "#3CBF71"],
			chart: {
				type: "bar",
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				bar: {
					horizontal: true,
					dataLabels: {
						position: "top"
					}
				}
			},
			dataLabels: {
				offsetX: -5,
				style: {
					fontSize: "12px",
					colors: ["white"]
				}
			},
			stroke: {
				width: 1,
				colors: ["#fff"]
			},
			xaxis: {
				categories: [
					"Online bank",
					"Vipps",
					"Accounting",
					"Email",
					"SMS"
				]
			},
			fill: {
				colors: ["#00519A", "#3CBF71"]
			}
		}
	};

	componentDidMount() {
		this.resizeHandler();
		window.addEventListener("resize", this.resizeHandler);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resizeHandler);
	}

	resizeHandler = () => {
		const legend = { ...this.state.options.legend };
		const plotOptions = { ...this.state.options.plotOptions };
		const dataLabels = { ...this.state.options.dataLabels };
		const stroke = { ...this.state.options.stroke };

		if (window.innerWidth <= 600 && window.innerWidth > 420) {
			legend.position = "top";
			plotOptions.bar.barHeight = "100%";
			dataLabels.style.fontSize = "12px";
			stroke.width = 1;
			this.setState({
				options: { legend, plotOptions, dataLabels, stroke }
			});
		} else if (window.innerWidth <= 420) {
			legend.position = "top";
			plotOptions.bar.barHeight = "100%";
			dataLabels.style.fontSize = "10px";
			stroke.width = 0;
			this.setState({
				options: { legend, plotOptions, dataLabels, stroke }
			});
		} else {
			legend.position = "right";
			plotOptions.bar.barHeight = "70%";
			dataLabels.style.fontSize = "12px";
			stroke.width = 1;
			this.setState({
				options: { legend, plotOptions, dataLabels, stroke }
			});
		}
	};

	render() {
		return (
			<div style={{ width: "100%" }}>
				<Chart
					options={this.state.options}
					series={
						this.props.showChart
							? this.state.series
							: this.state.dummySeries
					}
					type="bar"
				/>
			</div>
		);
	}
}

export default BarChart;
