import * as types from "../actions/types";

const initialState = {
	trees: 0,
	water: 0,
	co2: 0,
	unmountTime: null,
	mountTime: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case types.INCREMENT_COUNTER: {
			return {
				...state,
				trees: action.countTrees,
				water: action.countWater,
				co2: action.countCO2,
				unmountTime: action.date,
				seconds: action.seconds
			};
		}

		default:
			return state;
	}
};
